
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate, Link } from "react-router-dom";
import ProductService from "../../../services/admin/product.services";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCity, fetchAllDistrict, fetchAllState } from "../../../features/location.slice";
import DeliveryService from "../../../services/admin/delivery.services";
import { toast } from "react-toastify";
import { isEmpty } from "validator";

const initialValues = {
    product: "",
    state: "",
    district: "",
    cities: []
};

const LocationForm = () => {
    const params = useParams();
    const id = params.id;
    const [selectedCities, setSelectedCities] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialValues);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { stateData, districtData, cityData, statusState } = useSelector((state) => state.location);
    const [filteredCities, setFilteredCities] = useState(cityData); // State for filtered city list

    const validationSchema = Yup.object({
        product: Yup.string().required("Product is required"),
        state: Yup.string().required("State is required"),
        district: Yup.string().required("District is required"),
        cities: Yup.array().min(1, "At least one city must be selected").required("Cities are required")
    });


    // Update filtered cities when searchTerm changes
    useEffect(() => {
        if (searchTerm) {
            const filtered = cityData.filter(city =>
                city.place.toLowerCase().includes(searchTerm.toLowerCase()) ||
                city.zipcode.includes(searchTerm)
            );
            setFilteredCities(filtered);
        } else {
            setFilteredCities(cityData); // Reset filter when search term is empty
        }
        console.log(filteredCities); // Check filtered list
    }, [searchTerm, cityData, filteredCities]);


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term
    };

    const handleSubmit = async (values, { resetForm }) => {
        const selectedPins = selectedCities.map((city) => ({
            pincode: city.zipcode,
            available: true
        }));
        const data = {
            productId: products.find((product) => product.productName === values.product)?.id,
            state: values.state,
            district: values.district,
            pins: selectedPins
        };

        setLoading(true);
        try {
            let response;
            if (!isEmpty(id) && id > 0) {
                response = await DeliveryService.update(id, data);
            } else {
                response = await DeliveryService.create(data);
                resetForm();
            }

            if (response.data) {
                navigate("/admin/location");
                toast.success(id ? "Delivery updated successfully!" : "Delivery added successfully!");
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("Error submitting delivery:", error);
            toast.error(id ? "Failed to update delivery." : "Failed to create delivery.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (statusState === "idle") {
            dispatch(fetchAllState());
        }
    }, [dispatch, statusState]);

    const handleStateChange = (e, setFieldValue) => {
        const selectedState = e.target.value;
        setFieldValue("state", selectedState);
        setFieldValue("district", "");
        setFieldValue("cities", []);
        if (selectedState) {
            dispatch(fetchAllDistrict({ stateName: selectedState }));
        }
    };

    const handleDistrictChange = (e, setFieldValue) => {
        const selectedDistrict = e.target.value;
        setFieldValue("district", selectedDistrict);
        setFieldValue("cities", []);
        if (selectedDistrict) {
            dispatch(fetchAllCity({ districtName: selectedDistrict }));
        }
    };

    const handleCityChange = (e, setFieldValue) => {
        const cityName = e.target.value;
        const newSelectedCities = [...selectedCities];
        const selectedCityObj = cityData.find((city) => city.place === cityName);

        if (e.target.checked) {
            newSelectedCities.push(selectedCityObj);
        } else {
            const index = newSelectedCities.findIndex((city) => city.place === cityName);
            if (index > -1) {
                newSelectedCities.splice(index, 1);
            }
        }

        setSelectedCities(newSelectedCities);
        setFieldValue("cities", newSelectedCities);
    };

    const handleMarkAllChange = (e, setFieldValue) => {
        const allCities = e.target.checked ? cityData : [];
        setSelectedCities(allCities);
        setFieldValue("cities", allCities);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await ProductService.findAllProduct();
            setProducts(response.data.data.rows);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchDeliveryData = useCallback(async () => {
        try {
            if (!isEmpty(id) && id > 0) {
                const result = await DeliveryService.findOne(id);
                const responseData = result.data.data;

                if (responseData) {
                    const productName = responseData.product_master.productName;
                    const selectedCities = responseData.pins.map((pin) => ({
                        place: pin.pincode,
                        zipcode: pin.pincode,
                        available: pin.available
                    }));

                    setData({
                        product: productName,
                        state: responseData.state,
                        district: responseData.district,
                        cities: selectedCities
                    });

                    setSelectedCities(selectedCities);
                    dispatch(fetchAllDistrict({ stateName: responseData.state }));
                    dispatch(fetchAllCity({ districtName: responseData.district }));
                }
            }
        } catch (error) {
            console.error("Error fetching delivery data:", error);
            toast.error("Failed to load delivery data.");
        }
    }, [id, dispatch]);

    useEffect(() => {
        fetchDeliveryData();
    }, [fetchDeliveryData]);

    return (
        <div className="content">
            <div className="page-header">
                <div className="page-title">
                    <h4>{id > 0 ? "Edit Location" : "Add Location"}</h4>
                    <h6>{id > 0 ? "Update the location details" : "Create a new location"}</h6>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <Formik
                        initialValues={data}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    {/* Product Selection */}
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="product">Product <span className="manitory">*</span></label>
                                            <Field as="select" name="product" className="form-control">
                                                <option value="">Select Product</option>
                                                {products.map((product) => (
                                                    <option key={product.id} value={product.productName}>
                                                        {product.productName}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="product" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                    {/* State Selection */}
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="state">State <span className="manitory">*</span></label>
                                            <Field
                                                as="select"
                                                name="state"
                                                className="form-control"
                                                value={values.state}
                                                onChange={(e) => handleStateChange(e, setFieldValue)}
                                            >
                                                <option value="">Select State</option>
                                                {stateData.map((item, index) => (
                                                    <option key={index} value={item.state_code}>
                                                        {item.stateName}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="state" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                    {/* District Selection */}
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="district">District <span className="manitory">*</span></label>
                                            <Field
                                                as="select"
                                                name="district"
                                                className="form-control"
                                                value={values.district}
                                                onChange={(e) => handleDistrictChange(e, setFieldValue)}
                                            >
                                                <option value="">Select District</option>
                                                {districtData.map((item, index) => (
                                                    <option key={index} value={item.districtCode}>
                                                        {item.distName}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="district" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-6 col-12 border-separate">
                                        <div className="form-group border p-3">
                                            <label htmlFor="cities">Places <span className="manitory">*</span></label>

                                            {/* Container for the Mark All Checkbox and Search Box */}
                                            <div className="flex justify-between items-center mb-3">
                                                {/* Mark All Checkbox */}
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        id="mark-all"
                                                        className="form-check-input"
                                                        checked={selectedCities.length === filteredCities.length}
                                                        onChange={(e) => handleMarkAllChange(e, setFieldValue)}
                                                    />
                                                    <label htmlFor="mark-all" className="form-check-label">
                                                        Mark All
                                                    </label>
                                                </div>

                                                {/* Search Box */}
                                                <div className="search-set flex items-center">
                                                    <div className="search-input ml-4">
                                                        <Link className="btn btn-searchset">
                                                            <img src="../../../assets/img/icons/search-white.svg" alt="search-icon" />
                                                        </Link>
                                                        <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                            <label>
                                                                <input
                                                                    type="search"
                                                                    value={searchTerm}
                                                                    onChange={handleSearchChange} // Update search term on change
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Search..."
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* City checkboxes with Tailwind CSS Grid */}
                                            <div id="cities" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                                                {filteredCities.map((city, index) => (
                                                    <div key={index} className="form-check border-bottom py-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`city-${city.place}`}
                                                            name="cities"
                                                            value={city.place}
                                                            checked={selectedCities.some((c) => c.zipcode === city.zipcode)}
                                                            onChange={(e) => handleCityChange(e, setFieldValue)}
                                                            className="form-check-input"
                                                        />
                                                        <label htmlFor={`city-${city.place}`} className="form-check-label">
                                                            {city.place} {city.zipcode}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <ErrorMessage name="cities" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                {/* Submit and Cancel Buttons */}
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                                        {loading ? "Saving..." : (id > 0 ? "Update Location" : "Add Location")}
                                    </button>
                                    <Link to={"/admin/location"} className="btn btn-cancel" type="reset">
                                        Cancel
                                    </Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default LocationForm;
