
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrder, fetchAllOrder } from "../../../features/order.slice";
import { toast } from "react-toastify";

const OrderWidget = ({ iconSrc, count, label, colorClass, onClick }) => (
  <div
    className={`bg-white p-3 rounded-lg shadow-sm cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 ${colorClass}`}
    onClick={onClick} // Trigger the onClick handler when the widget is clicked
  >
    <div className="flex justify-center items-center mb-2">
      <img src={iconSrc} alt={label} className="w-6 h-6" />
    </div>
    <div className="text-center">
      <h5 className="text-xl font-semibold">{count}</h5>
      <h6 className="text-xs text-gray-500">{label}</h6>
    </div>
  </div>
);




const OrderItemDetails = ({ orderItem, BASE_URL }) => {
  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false); // State to control modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const dispatch = useDispatch();
  const openTrackModal = () => setIsTrackModalOpen(true);  // Function to open modal
  const closeTrackModal = () => setIsTrackModalOpen(false);  // Function to close modal
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(true); // Track button disabled state



  // Open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle cancel reason change
  const handleCancelReasonChange = (e) => {
    const value = e.target.value;
    setCancelReason(value);

    // Enable the cancel button if a reason is selected
    if (value !== '') {
      setIsCancelButtonDisabled(false);
    } else {
      setIsCancelButtonDisabled(true);
    }
  };


  // Handle order cancellation
  const handleCancelOrder = async () => {
    const orderNumber = orderItem.orderNumber || 0;
    const requestData = {
      cancelReason,
      otherReason,
      orderNumber
    }
    if (!cancelReason) {
      toast.error("Please select a cancellation reason.");
      return;
    }

    if (cancelReason === "Other" && !otherReason) {
      toast.error("Please provide a custom cancellation reason.");
      return;
    }
    // Process the cancellation with the selected reason
    await dispatch(cancelOrder(requestData)).unwrap();
    await dispatch(fetchAllOrder()).unwrap();
    toast.success("Order has been cancelled successfully.");
    closeModal(); // Close the modal after cancellation
  };

  const statusOrder = [
    "PLACED",
    "SHIPPED",
    "OFD",
    "DELIVERED",
    "CANCELLED"

  ];
  return (<>
    <div className="lg:col-span-2">
      <div key={orderItem.orderNumber} className="bg-white shadow-lg rounded-lg p-6 mb-2">
        <div className="totalitem mb-2">
          <p className="inline-block px-3 text-sm font-semibold text-white bg-gray-400 rounded-full">
            <small>ORDER: <span className="font-semibold text-white">#{orderItem.orderNumber}</span></small>
          </p>
          <p className="text-gray-600 uppercase">
            <small>Order Placed: <span className="font-semibold text-blue-400">
              {new Date(orderItem.createdAt).toLocaleDateString()}
            </span></small>
          </p>
        </div>

        {/* Shipping Address */}
        <div className="mt-2">
          <div className="flex flex-col sm:flex-row items-start border-b pb-2 mb-6">
            <div className="flex-grow overflow-hidden">
              <h6 className="font-semibold text-lg text-gray-800 truncate">Shipping Address</h6>
              <div className="text-yellow-400 mt-2">
                <p className="mt-2 text-gray-600"><span className="font-bold">{orderItem.address_master.fullName}</span></p>
                <p className="mt-1 text-gray-600"><span className="font-medium">{orderItem.address_master.phone}</span></p>
                <p className="text-gray-600">{orderItem.address_master.houseNo}{orderItem.address_master.landmark}</p>
                <p className="text-gray-600">{orderItem.address_master.city}{orderItem.address_master.district}{orderItem.address_master.state}</p>
                <p className="text-gray-600">{orderItem.address_master.pinCode} IN</p>
              </div>
            </div>

            {/* Payment Status */}
            <div className="flex-grow overflow-hidden">
              <h6 className="font-semibold text-lg text-gray-800 truncate">Payment Status</h6>
              <p className="mt-2 text-gray-600">Payment Status: <span className="font-medium uppercase text-green-400">{orderItem.paymentStatus}</span></p>
              <p className="mt-2 text-gray-600">Payment Mode: <span className="font-medium uppercase text-orange-400">{orderItem.paymentMethod}</span></p>
            </div>

            {/* Expected Delivery */}
            <div className="flex-grow overflow-hidden">
              <h6 className="font-semibold text-lg text-gray-800 truncate">Expected Delivery</h6>
              <div className="text-yellow-400 mt-2">
                <p className="mt-2 text-red-500"><span className="font-medium">{orderItem.expectedDelivery}</span></p>
              </div>
              {orderItem.paymentMethod === 'ONLINE' && (
                <>
                  <h6 className="font-semibold text-lg text-gray-800 truncate mt-4">Transaction ID</h6>
                  <div className="flex items-center mt-2">
                    <span className="text-sm font-semibold text-gray-900">{orderItem.razorpayPaymentId}</span>
                  </div>
                </>
              )}
            </div>
            <div className="flex-grow overflow-hidden">
              <div className="mt-2">
                <button onClick={openTrackModal} className="p-1 w-36 uppercase py-1 px-2 text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-4 focus:ring-sky-300 font-semibold text-sm rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                  Track
                </button>
              </div>
              <div className="mt-2">
                {orderItem.status !== 'CANCELLED' && orderItem.status !== 'DELIVERED' && (
                  <button onClick={openModal} className="p-1 w-36 uppercase py-1 px-2 text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-semibold text-sm rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                    Cancel
                  </button>
                )}

              </div>
              <div className="mt-2">
                {orderItem.status === 'DELIVERED' && (
                  <button className="p-1 w-36 uppercase py-1 px-2 text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-semibold text-sm rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                    Invoice
                  </button>
                )}
              </div>
            </div>

          </div>

          {/* Order Items */}
          <div className="order-items">
            {orderItem.order_items.length > 0 ? (
              orderItem.order_items.map((itm, i) => (
                <div className="flex flex-col sm:flex-row items-start border-b pb-1 mb-2 divide-y divide-dashed" key={i}>
                  <div className="mr-10 mb-4 sm:mb-0">
                    <img
                      crossOrigin="anonymous"
                      src={`${BASE_URL}${itm.productSlug}?t=${new Date().getTime()}`}
                      alt="Product"
                      className="rounded-md w-40 h-40 object-cover"
                    />
                  </div>
                  <div className="flex-grow overflow-hidden">
                    <h6 className="font-semibold text-lg text-gray-800 truncate">{itm.productName}</h6>
                    <p className="mt-2 text-gray-600">Rate: <span className="font-medium">₹{itm.price}</span></p>
                    <p className="mt-2 text-gray-600">Packet Size: <span className="font-medium">{itm.quantitySize}{itm.unit}</span></p>
                    <p className="mt-2 text-gray-600">Quantity: <span className="font-medium">{itm.quantity}</span></p>
                    <p className="mt-2 text-gray-600">Tax : <span className="font-medium">{itm.tax}</span></p>
                    <p className="mt-2 text-gray-600">Sub Total: <span className="font-medium">₹{parseFloat(itm.subTotalPrice).toFixed(2)}</span></p>
                  </div>
                </div>



                // <div className="flex flex-col sm:flex-row items-start border-b pb-1 mb-2 divide-y divide-dashed" key={i}>
                //   <div className="mr-10 mb-4 sm:mb-0">
                //     <img
                //       crossOrigin="anonymous"
                //       src={`${BASE_URL}${itm.productSlug}?t=${new Date().getTime()}`}
                //       alt="Product"
                //       className="rounded-md w-24 h-24 object-cover"
                //     />
                //   </div>
                //   <div className="flex-grow overflow-hidden">
                //     <h6 className="font-semibold text-lg text-gray-800 truncate">{itm.productName}</h6>
                //     <p className="mt-2 text-gray-600">Rate: <span className="font-medium">₹{itm.price}</span></p>
                //     <p className="mt-2 text-gray-600">Packet Size: <span className="font-medium">{itm.quantitySize}{itm.unit}</span></p>
                //     <p className="mt-2 text-gray-600">Quantity: <span className="font-medium">{itm.quantity}</span></p>
                //     <p className="mt-2 text-gray-600">Tax : <span className="font-medium">{itm.tax}</span></p>
                //     <p className="mt-2 text-gray-600">Sub Total: <span className="font-medium">₹{parseFloat(itm.subTotalPrice).toFixed(2)}</span></p>
                //   </div>
                // </div>
              ))
            ) : (
              <div>No products found.</div>
            )}
          </div>

          <div className="flex-grow overflow-hidden">
            <div className="flex items-center border-b pb-1 mb-2 divide-y divide-dashed">
              {/* Tax Amount */}
              <div className="flex-1 pr-4">
              </div>

              {/* Total Amount */}
              <div className="flex-1 pl-4">
                <h6 className="font-medium text-md text-gray-600 mt-2">
                  Delivery Charge: <span className="text-md font-extrabold text-gray-600">
                    ₹{parseFloat(orderItem.deliveryCharge).toFixed(2)}
                  </span>
                </h6>
                <h6 className="font-medium text-md text-gray-600 mt-2">
                  Total Tax: <span className="text-md font-extrabold text-gray-600">
                    ₹{parseFloat(orderItem.taxAmount).toFixed(2)}
                  </span>
                </h6>
                <h6 className="font-medium text-md text-gray-600 mt-2">
                  Total Amount: <span className="text-md font-extrabold text-gray-600">
                    ₹{parseFloat(orderItem.totalPrice).toFixed(2)}
                  </span>
                </h6>

              </div>

            </div>
          </div>



          {/* Action Buttons */}
          <div className="flex space-x-4 justify-center mt-8">
            {/* Handling Online Payment Success */}
            {orderItem.paymentStatus === 'PAID' && orderItem.paymentMethod === 'ONLINE' ? (
              <p className="px-6 py-2 uppercase text-green-500 font-semibold rounded-lg">PAYMENT SUCCESS</p>
            )
              // Handling Online Payment Failure or Pending
              : orderItem.paymentMethod === 'ONLINE' && (orderItem.paymentStatus === 'PENDING' || orderItem.paymentStatus === 'FAILURE') ? (
                <p className="px-6 py-2 uppercase text-red-500 font-semibold rounded-lg">PAYMENT FAILURE</p>
              )
                // Handling Cash On Delivery or Canceled Status
                : (
                  <>
                    {/* Show "Canceled" if the status is "CANCELLED" */}
                    {orderItem.status === "CANCELLED" && (
                      <span className="px-6 py-2 uppercase text-red-500 font-semibold rounded-lg">Order Canceled</span>
                    )}

                    {/* Show "Cash On Delivery" if the payment method is COD */}
                    {orderItem.status !== 'CANCELLED' && orderItem.paymentMethod === 'COD' && (
                      <p className="px-6 py-2 uppercase text-gray-500 font-semibold rounded-lg">Cash On Delivery</p>
                    )}
                  </>
                )}
          </div>


        </div>
      </div>
    </div>

    {isModalOpen && (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <h3 className="text-xl font-semibold mb-4 text-center">Cancel Order</h3>

          <div className="mb-4">
            <p className="text-gray-600 text-sm mb-2">Are you sure you want to cancel the order <strong>#{orderItem.orderNumber}</strong>?</p>

            <label htmlFor="cancelReason" className="block text-gray-700">Reason for Cancellation</label>
            <select
              id="cancelReason"
              className="mt-2 w-full px-3 py-2 border rounded-md bg-gray-100 text-gray-700"
              value={cancelReason}
              // onChange={(e) => setCancelReason(e.target.value)}
              onChange={handleCancelReasonChange}
            >
              <option value="">Select a reason</option>
              <option value="Order mistake">Order mistake</option>
              <option value="Changed mind">Changed mind</option>
              <option value="Shipping delay">Shipping delay</option>
              <option value="Better price elsewhere">Found a better price elsewhere</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {cancelReason === "Other" && (
            <div className="mt-4">
              <label htmlFor="otherReason" className="block text-gray-700">Other reason (Optional)</label>
              <textarea
                id="otherReason"
                rows="3"
                className="mt-2 w-full px-3 py-2 border rounded-md bg-gray-100 text-gray-700"
                placeholder="Describe the reason for cancellation"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              ></textarea>
            </div>
          )}

          <div className="flex justify-between mt-6">
            <button
              onClick={closeModal}
              className="px-4 py-1 bg-gray-600 text-white rounded-lg"
            >
              Close
            </button>
            <button
              onClick={handleCancelOrder}
              disabled={isCancelButtonDisabled}  // Disable the button if no reason is selected
              className={`px-4 py-1 ${isCancelButtonDisabled ? "bg-gray-400" : "bg-red-600"} text-white rounded-lg`}
            >
              Confirm Cancellation
            </button>
          </div>
        </div>
      </div>
    )}
    {isTrackModalOpen && (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <section className="bg-gray-100 shadow-sm rounded-lg p-4 max-w-3xl mx-auto mb-6 mt-2">
            <div className="flex justify-center items-center">
              {/* Order Status Badge */}
              <div
                className={`text-xs font-semibold px-3 py-1 rounded-full ${orderItem.status === "PLACED"
                  ? "bg-blue-500 text-white"
                  : orderItem.status === "SHIPPED"
                    ? "bg-yellow-500 text-white"
                    : orderItem.status === "DELIVERED"
                      ? "bg-green-500 text-white"
                      : orderItem.status === "CANCELLED"
                        ? "bg-red-500 text-white"
                        : "bg-gray-500 text-white"
                  }`}
              >
                {orderItem.status}
              </div>
            </div>

            <div className="mt-3">
              <h3 className="text-xl font-semibold mb-4 text-center">Order Progress</h3>

              {/* Progress Bar */}
              <div className="relative w-full h-1.5 bg-gray-300 rounded-full">
                <div
                  className={`h-full rounded-full transition-all duration-300 ${orderItem.status === "CANCELLED" ? "bg-red-500" : "bg-green-500"
                    }`}
                  style={{
                    width: `${(((statusOrder.indexOf(orderItem.status)) + 1) /
                      (orderItem.status === "CANCELLED" ? statusOrder.length : statusOrder.length - 1)) * 100}%`,
                  }}
                ></div>

              </div>

              {/* Progress Stages */}
              <div className="flex justify-between mt-2 text-xs text-gray-600 space-x-3">
                <span>Placed</span>
                {/* Conditionally render the rest of the stages if the order is not canceled */}
                {orderItem.status !== "CANCELLED" && (
                  <>
                    <span>Shipped</span>
                    <span>Out for Delivery</span>
                    <span>Delivered</span>
                  </>
                )}

                {/* Show "Canceled" status if the order is canceled */}
                {orderItem.status === "CANCELLED" && <span>Canceled</span>}
              </div>
            </div>
          </section>

          <div className="flex justify-end mt-2">
            <button
              onClick={closeTrackModal}
              className="px-4 py-1 bg-gray-600 text-white rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )}
  </>
  )
};



const MyOrderPage = () => {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { items, status, error } = useSelector((state) => state.order);

  // State for the selected order status filter
  const [selectedStatus, setSelectedStatus] = useState("all");

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAllOrder());
    }
  }, [status, dispatch]);

  // Function to count orders by status
  const countStatuses = (items) => {
    return items.reduce((acc, item) => {
      acc.total += 1;
      acc[item.status] = acc[item.status] ? acc[item.status] + 1 : 1;
      return acc;
    }, { total: 0 });
  };

  const orderCounts = countStatuses(items);

  // Filter orders by selected status
  const filteredOrders = selectedStatus === "all" ? items : items.filter(order => order.status === selectedStatus);

  // Handler to update the selected status when an OrderWidget is clicked
  const handleWidgetClick = (status) => {
    setSelectedStatus(status); // Update the selected status state
  };

  return (
    <div className="page-wrapper ms-0 bg-gray-50">
      <div className="container mx-auto px-4 py-4">
        <h4 className="text-2xl font-semibold text-gray-800 mb-6">My Orders</h4>

        {/* Order Widgets */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          <OrderWidget
            iconSrc="../../../assets/img/icons/dash1.svg"
            count={orderCounts.total || 0}
            label="Total Orders"
            colorClass="text-orange-400"
            onClick={() => handleWidgetClick("all")} // Filter by all orders
          />
          <OrderWidget
            iconSrc="../../../assets/img/icons/dash2.svg"
            count={orderCounts.PLACED || 0}
            label="Placed Orders"
            colorClass="text-green-500"
            onClick={() => handleWidgetClick("PLACED")} // Filter by PLACED orders
          />
          <OrderWidget
            iconSrc="../../../assets/img/icons/dash3.svg"
            count={orderCounts.DELIVERED || 0}
            label="Completed Orders"
            colorClass="text-sky-400"
            onClick={() => handleWidgetClick("DELIVERED")} // Filter by DELIVERED orders
          />
          <OrderWidget
            iconSrc="../../../assets/img/icons/dash4.svg"
            count={orderCounts.CANCELLED || 0}
            label="Canceled Orders"
            colorClass="text-red-500"
            onClick={() => handleWidgetClick("CANCELLED")} // Filter by CANCELLED orders
          />
        </div>

        {/* Order List */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          {status === "loading" && <p>Loading data...</p>}
          {status === "failed" && <p>{error || "Failed to load data."}</p>}
          {status === "succeeded" && (
            <>
              {filteredOrders.length > 0 ? (
                filteredOrders.map((orderItem) => (
                  <OrderItemDetails key={orderItem.orderNumber} orderItem={orderItem} BASE_URL={BASE_URL} />
                ))
              ) : (
                <div className="lg:col-span-2">
                  <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                    <p className="text-1xl font-semibold text-gray-600">No order data available for the selected status!</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrderPage;
