// import React, { useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Link, useParams } from "react-router-dom";
// import ReactImageMagnify from "@blacklab/react-image-magnify";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchProductById } from "../../../features/product.slice";
// import { addToCartAPI } from "../../../features/cart.slice";
// import Snackbar from "../../../components/Snackbar/Snackbar";
// import { HeartIcon } from "@heroicons/react/20/solid";
// import ProductSizeFilter from "./ProductSizeFilter";

// const ProductDetailsPage = () => {
//     // eslint-disable-next-line no-unused-vars
//     const [quantity, setQuantity] = useState(1);
//     const { productId } = useParams();
//     const dispatch = useDispatch();
//     const BASE_URL = process.env.REACT_APP_API_URL;
//     const { product, loading, error } = useSelector((state) => state.products);
//     const [snackbarMessage, setSnackbarMessage] = useState("");
//     const [snackbarVisible, setSnackbarVisible] = useState(false);
//     const [loadings, setLoading] = useState(false);
//     const [isInWishlist, setIsInWishlist] = useState(false);
//     const [activeImage, setActiveImage] = useState("");

//     useEffect(() => {
//         if (productId) {
//             dispatch(fetchProductById(productId));
//         }
//     }, [dispatch, productId]);

//     const HandleAddToCart = (product) => {
//         setLoading(true);
//         dispatch(addToCartAPI({ productId: product.id, quantity: quantity }))
//             .then(() => {
//                 setSnackbarMessage(`${product.productName} added to cart!`);
//                 setSnackbarVisible(true);
//                 setTimeout(() => setLoading(false), 600);
//                 setTimeout(() => setSnackbarVisible(false), 3000);
//             })
//             .catch(() => {
//                 setSnackbarMessage("Failed to add item to cart.");
//                 setSnackbarVisible(true);
//                 setLoading(false);
//                 setTimeout(() => setSnackbarVisible(false), 3000);
//             });
//     };

//     // Handler to change active image on thumbnail click
//     const handleThumbnailClick = (image) => {
//         setActiveImage(image);
//     };

//     // Toggle wishlist status on click
//     const toggleWishlist = () => {
//         setIsInWishlist(!isInWishlist);
//     };

//     return (
//         <>
//             <div className="page-wrapper ms-0">
//                 <div className="content">
//                     {loading ? (
//                         <div className="flex flex-wrap lg:flex-nowrap">
//                             <aside className="lg:w-1/2 w-full">
//                                 <div className="animate-pulse h-[30vh] lg:h-[50vh] bg-gray-200 rounded-md"></div>
//                                 <div className="mt-2 lg:mt-4 h-24 space-x-2 flex overflow-x-auto">
//                                     {[...Array(4)].map((_, index) => (
//                                         <div key={index} className="h-16 w-16 bg-gray-200 rounded-md animate-pulse"></div>
//                                     ))}
//                                 </div>
//                             </aside>

//                             <main className="lg:w-1/2 lg:pl-8 w-full">
//                                 <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
//                                     <article>
//                                         <div className="w-full h-6 bg-gray-200 rounded-md mb-3 animate-pulse"></div>
//                                         <div className="flex items-center space-x-2">
//                                             <div className="w-24 h-4 bg-gray-200 rounded-md animate-pulse"></div>
//                                         </div>
//                                         <div className="w-32 h-6 bg-gray-200 rounded-md mt-2 mb-3 animate-pulse"></div>
//                                         <div className="w-full h-4 bg-gray-200 rounded-md mb-3 animate-pulse"></div>
//                                         {[...Array(4)].map((_, index) => (
//                                             <div key={index} className="w-full h-4 bg-gray-200 rounded-md mb-2 animate-pulse"></div>
//                                         ))}
//                                         <div className="flex space-x-3 mt-4">
//                                             <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
//                                             <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
//                                         </div>
//                                     </article>
//                                 </div>
//                             </main>
//                         </div>
//                     ) : (
//                         <>
//                             {error && (
//                                 <div className="flex space-x-3 justify-center">
//                                     <p>Error: {error}</p>
//                                     {error.includes("404") && <p>The products you are looking for are not available.</p>}
//                                 </div>
//                             )}

//                             {product && Object.keys(product).length > 0 ? (
//                                 <div className="flex flex-wrap lg:flex-nowrap">
//                                     <aside className="lg:w-1/2 w-full mt-2">
//                                         {/* Main Product Image */}
//                                         <Swiper spaceBetween={5} slidesPerView={1} loop={true} className="h-[30vh] lg:h-[60vh] rounded-md shadow-md">
//                                             <SwiperSlide>

//                                                 <div className="rounded-md overflow-hidden relative">
//                                                     <div
//                                                         className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-md cursor-pointer"
//                                                         onClick={toggleWishlist}
//                                                     >
//                                                         <HeartIcon className={`h-5 w-5 ${isInWishlist ? 'text-red-500' : 'text-gray-500'}`} />
//                                                     </div>
//                                                     <ReactImageMagnify
//                                                         imageProps={{
//                                                             crossOrigin: "anonymous",
//                                                             alt: "Product Image",
//                                                             src: activeImage || `${BASE_URL}${product.productImages[0].attachmentName}`,
//                                                             width: 390,
//                                                             height: 340,
//                                                         }}
//                                                         magnifyContainerProps={{
//                                                             scale: 1.0,
//                                                         }}
//                                                         magnifiedImageProps={{
//                                                             crossOrigin: "anonymous",
//                                                             height: 800,
//                                                             src: activeImage || `${BASE_URL}${product.productImages[0].attachmentName}`,
//                                                             width: 800,
//                                                         }}
//                                                         portalProps={{
//                                                             horizontalOffset: 0,
//                                                            verticalOffset: 0,
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </SwiperSlide>
//                                         </Swiper>

//                                         {/* Thumbnail Image Gallery */}
//                                         <Swiper
//                                             spaceBetween={5}
//                                             slidesPerView={1}
//                                             loop={true}
//                                             className="thumbs-wrap mt-2 lg:mt-4 h-24 rounded-md"
//                                             breakpoints={{
//                                                 1024: { slidesPerView: 4 },
//                                                 768: { slidesPerView: 3 },
//                                                 480: { slidesPerView: 2 },
//                                                 319: { slidesPerView: 1 },
//                                             }}
//                                         >
//                                             {product.productImages &&
//                                                 product.productImages.map((image, index) => (
//                                                     <SwiperSlide key={index}>
//                                                         <Link
//                                                             className="item-thumb"
//                                                             onClick={() => handleThumbnailClick(`${BASE_URL}${image.attachmentName}`)}
//                                                         >
//                                                             <div className="rounded-md overflow-hidden">
//                                                                 <img
//                                                                     crossOrigin="anonymous"
//                                                                     src={`${BASE_URL}${image.attachmentName}`}
//                                                                     alt={`Thumbnail ${product.productName}`}
//                                                                     className="w-20 h-20 object-cover rounded-md transition-transform transform hover:scale-110"
//                                                                 />
//                                                             </div>
//                                                         </Link>
//                                                     </SwiperSlide>
//                                                 ))}
//                                         </Swiper>
//                                     </aside>


//                                     <ProductSizeFilter
//                                         product={product}
//                                         onAddToCart={HandleAddToCart}
//                                         loadings={loadings}
//                                     />

//                                 </div>
//                             ) : (
//                                 <div className="flex space-x-3 justify-center">Product not found</div>
//                             )}

//                         </>
//                     )}
//                 </div>
//             </div>

//             {snackbarVisible && (
//                 <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
//             )}
//         </>
//     );
// };
// export default  ProductDetailsPage;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link, useParams } from "react-router-dom";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductById } from "../../../features/product.slice";
import { addToCartAPI } from "../../../features/cart.slice";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { HeartIcon } from "@heroicons/react/20/solid";
import ProductSizeFilter from "./ProductSizeFilter";

const ProductDetailsPage = () => {
    // eslint-disable-next-line no-unused-vars
    const [quantity, setQuantity] = useState(1);
    const { productId } = useParams();
    const dispatch = useDispatch();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const { product, loading, error } = useSelector((state) => state.products);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [loadings, setLoading] = useState(false);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const [activeImage, setActiveImage] = useState("");

    useEffect(() => {
        if (productId) {
            dispatch(fetchProductById(productId));
        }
    }, [dispatch, productId]);

    useEffect(() => {
        // Set the first image as the active image initially
        if (product && product.productImages && product.productImages.length > 0) {
            setActiveImage(`${BASE_URL}${product.productImages[0].attachmentName}`);
        }
    }, [BASE_URL, product]);

    const onAddToCart = ({ product, quantitySizeId }) => {
        setLoading(true);
        dispatch(addToCartAPI({ productId: product.id, quantity,quantitySizeId }))
            .then(() => {
                setSnackbarMessage(`${product.productName} added to cart!`);
                setSnackbarVisible(true);
                setTimeout(() => setLoading(false), 600);
                setTimeout(() => setSnackbarVisible(false), 3000);
            })
            .catch(() => {
                setSnackbarMessage("Failed to add item to cart.");
                setSnackbarVisible(true);
                setLoading(false);
                setTimeout(() => setSnackbarVisible(false), 3000);
            });
    };
    const handleThumbnailClick = (image) => {
        setActiveImage(image);
    };

    const toggleWishlist = () => {
        setIsInWishlist(!isInWishlist);
    };

    return (
        <>
            <div className="page-wrapper ms-0">
                <div className="content">
                    {loading ? (
                        <div className="flex flex-wrap lg:flex-nowrap">
                            <aside className="lg:w-1/2 w-full">
                                <div className="animate-pulse h-[30vh] lg:h-[50vh] bg-gray-200 rounded-md"></div>
                                <div className="mt-2 lg:mt-4 h-24 space-x-2 flex overflow-x-auto">
                                    {[...Array(4)].map((_, index) => (
                                        <div key={index} className="h-16 w-16 bg-gray-200 rounded-md animate-pulse"></div>
                                    ))}
                                </div>
                            </aside>

                            <main className="lg:w-1/2 lg:pl-8 w-full">
                                <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
                                    <article>
                                        <div className="w-full h-6 bg-gray-200 rounded-md mb-3 animate-pulse"></div>
                                        <div className="flex items-center space-x-2">
                                            <div className="w-24 h-4 bg-gray-200 rounded-md animate-pulse"></div>
                                        </div>
                                        <div className="w-32 h-6 bg-gray-200 rounded-md mt-2 mb-3 animate-pulse"></div>
                                        <div className="w-full h-4 bg-gray-200 rounded-md mb-3 animate-pulse"></div>
                                        {[...Array(4)].map((_, index) => (
                                            <div key={index} className="w-full h-4 bg-gray-200 rounded-md mb-2 animate-pulse"></div>
                                        ))}
                                        <div className="flex space-x-3 mt-4">
                                            <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
                                            <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
                                        </div>
                                    </article>
                                </div>
                            </main>
                        </div>
                    ) : (
                        <>
                            {error && (
                                <div className="flex space-x-3 justify-center">
                                    <p>Error: {error}</p>
                                    {error.includes("404") && <p>The product you are looking for is not available.</p>}
                                </div>
                            )}

                            {product && Object.keys(product).length > 0 ? (
                                <div className="flex flex-wrap lg:flex-nowrap">
                                    <aside className="lg:w-1/2 w-full mt-2">
                                        {/* Main Product Image */}
                                        <Swiper 
                                            spaceBetween={5}
                                            slidesPerView={1}
                                            loop={true}
                                            slidesPerGroup= {3}
                                                                               
                                            className="h-[30vh] lg:h-[60vh] rounded-md shadow-md">

                                            <SwiperSlide>
                                                <div className="rounded-md overflow-hidden relative">
                                                    <div
                                                        className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-md cursor-pointer"
                                                        onClick={toggleWishlist}
                                                    >
                                                        <HeartIcon className={`h-5 w-5 ${isInWishlist ? 'text-red-500' : 'text-gray-500'}`} />
                                                    </div>
                                                    <ReactImageMagnify
                                                        imageProps={{
                                                            crossOrigin: "anonymous",
                                                            alt: "Product Image",
                                                            src: activeImage || `${BASE_URL}${product.productImages[0].attachmentName}`,
                                                            width: 390,
                                                            height: 340,
                                                        }}
                                                        magnifyContainerProps={{
                                                            scale: 1.0,
                                                        }}
                                                        magnifiedImageProps={{
                                                            crossOrigin: "anonymous",
                                                            height: 800,
                                                            src: activeImage || `${BASE_URL}${product.productImages[0].attachmentName}`,
                                                            width: 800,
                                                        }}
                                                        portalProps={{
                                                            horizontalOffset: 0,
                                                            //verticalOffset: 0,
                                                        }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>

                                        {/* Thumbnail Image Gallery */}
                                        <Swiper
                                            spaceBetween={5}
                                            slidesPerView={1}
                                            loop={true}
                                            className="thumbs-wrap mt-2 lg:mt-4 h-24 rounded-md"
                                            breakpoints={{
                                                1024: { slidesPerView: 4 },
                                                768: { slidesPerView: 3 },
                                                480: { slidesPerView: 2 },
                                                319: { slidesPerView: 1 },
                                            }}
                                        >
                                            {product.productImages &&
                                                product.productImages.map((image, index) => (
                                                    <SwiperSlide key={index}>
                                                        <Link
                                                            className="item-thumb"
                                                            onClick={() => handleThumbnailClick(`${BASE_URL}${image.attachmentName}`)}
                                                        >
                                                            <div className="rounded-md overflow-hidden">
                                                                <img
                                                                    crossOrigin="anonymous"
                                                                    src={`${BASE_URL}${image.attachmentName}`}
                                                                    alt={`Thumbnail ${product.productName}`}
                                                                    className="w-20 h-20 object-cover rounded-md transition-transform transform hover:scale-110"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                        
                                    </aside>

                                    <ProductSizeFilter
                                        product={product}
                                        onAddToCart={onAddToCart}
                                        loadings={loadings}
                                    />
                                    
                                </div>
                            ) : (
                                <div className="flex space-x-3 justify-center">Product not found</div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {snackbarVisible && (
                <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
            )}
        </>
    );
};

export default ProductDetailsPage;
