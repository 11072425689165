import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ProductList from './ProductList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../features/category.slice';
import { fetchAllProductsFilter } from '../../../features/product.slice';

const ShopPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [price, setPrice] = useState('');
  const [delivery, setDelivery] = useState(false);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const { items, status: productsStatus, error: productsError } = useSelector((state) => state.products);
  const { categories, status: categoriesStatus, error: categoriesError } = useSelector((state) => state.categories);

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params;
  };

  const searchQuery = getQueryParams().get('search') || '';
  const categoriesQuery = getQueryParams().get('categories') || '';

  useEffect(() => {
    if (categoriesStatus === 'idle') {
      dispatch(fetchCategories());
    }
  }, [categoriesStatus, dispatch]);

  useEffect(() => {
    const filter = {
      categoryId: categoriesQuery,
      search: searchQuery
    };
    dispatch(fetchAllProductsFilter(filter));
  }, [categoriesQuery, searchQuery, dispatch]);

  const onCategoryChange = (categoryId) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set('categories', categoryId);
    navigate(`?${newParams.toString()}`);

    const filter = {
      categoryId,
      search: searchQuery
    };
    dispatch(fetchAllProductsFilter(filter));
  };

  const sellers = [
    { id: '1', name: 'Seller A' },
    { id: '2', name: 'Seller B' },
    { id: '3', name: 'Seller C' },
  ];

  const onPriceChange = (priceValue) => setPrice(priceValue);
  const onDeliveryChange = (isChecked) => setDelivery(isChecked);
  const onSellerChange = (isChecked, sellerId) => {
    if (isChecked) {
      setSelectedSellers((prev) => [...prev, sellerId]);
    } else {
      setSelectedSellers((prev) => prev.filter(id => id !== sellerId));
    }
  };
  const isLoading = productsStatus === 'loading' || categoriesStatus === 'loading';
  const hasError = productsError || categoriesError;

  return (
    <div className="page-wrapper ms-0">
      <div className="content">
        <div className="container mx-auto p-8">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full sm:w-full md:w-1/4">
              <h2 className="text-1xl font-bold mb-4 text-gray-800">Filters</h2>
              <Sidebar
               categories={categories}
               onCategoryChange={onCategoryChange}
               onPriceChange={onPriceChange}
               onDeliveryChange={onDeliveryChange}
               onSellerChange={onSellerChange}
               sellers={sellers}
              />
            </div>

            <div className="md:w-3/4 flex-1">
              <h2 className="text-1xl font-bold mb-4 text-gray-800">Shop Products</h2>

              {isLoading ? (
                <div className="text-center py-4">
                  <div className="flex justify-center items-center space-x-2">
                    <div className="w-3 h-3 bg-gray-500 rounded-full animate-bounce delay-200"></div>
                    <div className="w-3 h-3 bg-gray-500 rounded-full animate-bounce delay-400"></div>
                    <div className="w-3 h-3 bg-gray-500 rounded-full animate-bounce delay-600"></div>
                  </div>
                </div>
              ) : hasError ? (
                <div className="text-center text-red-500 py-4">
                  <p>Error loading products or categories. Please try again later.</p>
                </div>
              ) : Array.isArray(items) && items.length === 0 ? (
                <div className="text-center py-4">
                  <p>No products found.</p>
                </div>
              ) : (
                <div className="row">
                  {items.map((product, index) => (
                    <ProductList key={index} product={product} />
                  ))}
                </div>

              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
