import React, { useState, useEffect, useCallback } from "react";
import DataTable from "../../../components/Datatable/DataTable"
import { Link } from "react-router-dom"
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeliveryService from "../../../services/admin/delivery.services";
import PinsTableCell from "../../../components/Datatable/PinsTableCell";

const Location = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;


  const columns = [
    {
      field: 'id',  // Directly maps to `id` in the object
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>;
      }
    },

    {
      field: 'attachmentName',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        const imageUrl = `${BASE_URL}${params.row.product_master.productImages[0].attachmentName}?t=${new Date().getTime()}`;
        return (
          <img
            className="product-img"
            crossOrigin="anonymous"
            src={imageUrl}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />
        );
      },
    },

    {
      field: 'sku',  // Directly maps to `product_master.stock`
      headerName: 'sku',
      width: 100,
      renderCell: (params) => params.row.product_master.sku
    },

    {
      field: 'productName',  // Directly maps to `product_master.productName`
      headerName: 'Product Name',
      width: 200,
      renderCell: (params) => params.row.product_master.productName
    },

    {
      field: 'state',  // Directly maps to `state` in the object
      headerName: 'State',
      width: 100,
      renderCell: (params) => params.row.state
    },

    {
      field: 'district',  // Directly maps to `district` in the object
      headerName: 'District',
      width: 150,
      renderCell: (params) => params.row.district
    },
    {
      field: 'pins',
      headerName: 'Pincode',
      width: 200,
      renderCell: (params) => {
        const pins = params.row.pins;

        return <PinsTableCell pins={pins} />;
      }
    },

    {
      field: 'action',  // Action field for edit and delete
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <div className="flex mt-3">
          <Link className="me-3" to={`edit/${params.row.id}`}>
            <PencilIcon className="h-5 w-5 text-green-600" />
          </Link>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
            <TrashIcon className="h-5 w-5 text-red-600" />
          </Link>
        </div>
      ),
    },
  ];



  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await DeliveryService.findAll(page, pageSize, searchTerm);
      console.log(response.data, "findalldelivery")

      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await DeliveryService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The delivery has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting delivery: ' + error);
      console.error('Error deleting delivery:', error);
    }
  };

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Location List</h4>
            <h6>Manage your location</h6>
          </div>
          <div className="page-btn">
            <Link to={"add"} className="btn btn-added"><img src="../../../assets/img/icons/plus.svg" className="me-2" alt="img" />Add Location</Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                    <input type="search" value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
            <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Location;
