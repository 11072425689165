
// export default OrderConfirmPage;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddressDropdown from "../../../components/address/Address";
import CouponServices from "../../../services/user/coupons.services";
import { createOrder } from "../../../features/order.slice";
import { resetCart } from "../../../features/cart.slice";

const OrderConfirmPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, totalPrice, status, error } = useSelector((state) => state.cart);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const pincode = location.state?.pincode;
  const delivery = location.state?.delivery || 0;

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");

  useEffect(() => {
    if (snackbarVisible) {
      const timer = setTimeout(() => setSnackbarVisible(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [snackbarVisible]);

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setSnackbarMessage("Coupon code cannot be empty.");
      setSnackbarVisible(true);
      return;
    }

    try {
      const response = await CouponServices.validateCoupons(couponCode);
      const coupon = response?.data?.data;

      if (coupon && coupon.isActive && new Date(coupon.expirationDate) > new Date()) {
        setDiscount(coupon.amount);
        setSnackbarMessage(`Coupon applied: ${coupon.title}`);
      } else {
        setDiscount(0); // Reset discount
        setSnackbarMessage("Invalid or expired coupon");
      }
      setSnackbarVisible(true);
    } catch {
      setSnackbarMessage("Failed to apply coupon. Please try again.");
      setSnackbarVisible(true);
    }
  };

  const handleConfirmOrder = async () => {
    setAddressError(""); // Reset errors before validating
    setPaymentMethodError(""); // Reset errors before validating

    if (items.length === 0 || !selectedAddress || !paymentMode) {
      if (items.length === 0) setAddressError("Your cart is empty.");
      if (!selectedAddress) setAddressError("Please select an address.");
      if (!paymentMode) setPaymentMethodError("Please select a payment method.");
      return;
    }

    if (selectedAddress.pinCode !== pincode) {
      setAddressError("Delivery is not available at this address.");
      return;
    }
    console.log(items);
    const totalQuantity = items.reduce((sum, item) => sum + item.quantity, 0);
    const requestData = {
      cartItem: items.map(({ productId, quantitySizeId, size, name, price, quantity, productSlug, unit, taxpar }) => ({
        productId, quantitySizeId, tax: taxpar, productName: name, price, quantity, quantitySize: size, productSlug, unit
      })),
      couponCode: couponCode || "None",
      addressId: selectedAddress.id,
      deliveryCharge: delivery,
      shippingAddress: `${selectedAddress.houseNo}, ${selectedAddress.landmark}, ${selectedAddress.city}, ${selectedAddress.district}, ${selectedAddress.state}, ${selectedAddress.pinCode}`,
      quantity: totalQuantity,
      paymentMethod: paymentMode
    };

    try {
      //console.log("requestData: ", requestData);
      const successMessage = paymentMode === "COD"
        ? 'Order placed successfully! Payment will be collected on delivery.'
        : 'Order Confirm successfully! Payment will be online.';

      const orderData = await dispatch(createOrder(requestData)).unwrap();
      dispatch(resetCart());
      toast.success(successMessage);
      if (paymentMode === "COD") {
        navigate('/user/payment-result', { state: { id: orderData.orderNumber || 0, status: 'cod' } });
      } else {
        navigate('/user/checkout', { state: { id: orderData.id || 0 } });
      }
    } catch (error) {
      const errorMessage = error?.message || "An error occurred. Please try again.";
      console.error("Order submission failed:", error);
      setAddressError(errorMessage);
    }
  };
  const subTotal = totalPrice.totalPriceBeforeTax;
  const totalTax = totalPrice.totalTax;
  const totalAmount = totalPrice.totalAmount + delivery;

  if (items.length === 0) {
    return (
      <div className="page-wrapper ms-0 bg-gray-50">
        <div className="content px-6 py-8">
          <p>Your cart is empty.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="page-wrapper ms-0 bg-gray-50">
      <div className="content px-6 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 bg-white shadow-md rounded-lg">
            <div className="px-6 py-6">
              <h4 className="text-2xl font-semibold text-gray-800 mb-6">Order Confirmation</h4>
              {status === "loading" && (
                <div className="flex mb-4 justify-between border-b py-4 animate-pulse">
                  <div className="w-24 h-24 bg-gray-300 rounded-md" />
                  <div className="flex-1 space-y-2">
                    <div className="h-6 bg-gray-300 rounded-md w-3/4" />
                    <div className="h-4 bg-gray-300 rounded-md w-1/2" />
                    <div className="h-4 bg-gray-300 rounded-md w-1/3" />
                  </div>
                </div>
              )}
              {/* Loading State */}
              {status === 'failed' && (
                <div>
                  <p>Error: {error}</p>
                  {error.includes('404') && <p>Your cart is empty.</p>}
                  {error.includes('500') && <p>There was an issue with the server. Please try again later.</p>}
                  {error.includes('403') && <p>You are not authorized to view this page.</p>}
                </div>
              )}
              {/* Empty Cart State */}
              {items.length === 0 ? (
                <div className="text-center p-6 text-gray-500">
                  <p>Order confirm page is empty.</p>
                </div>
              ) : (
                <>
                  {/* Items and details */}
                  <div>
                    <div className="totalitem mb-6">
                      <h4 className="text-lg font-medium text-gray-700">Total items: {items.length}</h4>
                    </div>

                    {items.map((item) => (
                      <div key={item.id} className="flex flex-col sm:flex-row items-center justify-between border-b py-4">
                        <img
                          crossOrigin="anonymous"
                          src={`${BASE_URL}/${item.productSlug}?t=${new Date().getTime()}`}
                          alt={item.name}
                          className="w-20 h-20 object-cover rounded-md sm:w-24 sm:h-24"
                        />
                        <div className="flex-1 pl-5 mt-4 sm:mt-0">
                          <p className="font-medium text-lg text-gray-800">{item.name}</p>
                          <p className="text-sm text-gray-500">Rate: ₹{item.price}</p>
                          <p className="text-sm text-gray-500">Packet Size: {item.size && item.unit ? (
                            <span className="ml-1 text-red-500 font-bold">{item.size} {item.unit}</span>
                          ) : (
                            <span className="text-gray-400">Unavailable</span>
                          )}</p>
                          <p className="text-gray-500 text-sm">Tax ({(item.taxpar * 100).toFixed(1)}%): ₹{(item.taxAmount).toFixed(2)}</p>
                          <p className="text-gray-500 text-sm">Sub Total: ₹{(item.subTotal).toFixed(2)}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-lg-6 col-sm-6 col-12 p-2">
                    <div className="form-group">
                      <div className="flex justify-center">
                        <AddressDropdown setSelectedAddress={setSelectedAddress} />
                      </div>
                      {addressError ? (
                        <p className="text-red-500 text-sm mt-2">{addressError}</p>
                      ) : pincode && items.length > 0 ? (
                        <p className="text-green-600 text-sm">Delivery is available to {pincode}</p>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <aside className="lg:col-span-1 bg-white shadow-md rounded-lg px-6 py-8">
            <h4 className="text-xl font-semibold text-gray-800 mb-6">Order Summary</h4>
            <div className="space-y-4">
              <div className="flex justify-between text-gray-700">
                <span>Total Price:</span>
                <span>₹{subTotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-700">
                <span>Discount:</span>
                <span className="text-green-600">- ₹{discount}</span>
              </div>
              <div className="flex justify-between text-gray-700">
                <span>Tax:</span>
                <span>₹{totalTax.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-700">
                <span>Delivery Charges:</span>
                <span>₹{delivery}</span>
              </div>
              <hr className="my-4 border-t border-gray-300" />
              <div className="flex justify-between text-lg font-semibold text-gray-800">
                <span>Total:</span>
                <span>₹{(totalAmount - discount).toFixed(2)}</span>
              </div>
            </div>

            <div className="mt-6">
              <label className="block text-gray-700">Have a coupon?</label>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  className="w-full p-2 border rounded-md"
                  placeholder="Coupon code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <button
                  onClick={handleApplyCoupon}
                  className="px-4 uppercase py-2 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Apply
                </button>
              </div>
            </div>

            <div className="mt-6">
              <label className="block text-gray-700 mb-2">Payment Method</label>

              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="COD"
                    name="paymentMethod"
                    value="COD"
                    checked={paymentMode === "COD"}
                    onChange={(e) => setPaymentMode(e.target.value)}
                    className="mr-2"
                  />
                  <label htmlFor="COD" className="text-gray-700">CASH</label>
                </div>

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="ONLINE"
                    name="paymentMethod"
                    value="ONLINE"
                    checked={paymentMode === "ONLINE"}
                    onChange={(e) => setPaymentMode(e.target.value)}
                    className="mr-2"
                  />
                  <label htmlFor="ONLINE" className="text-gray-700">ONLINE</label>
                </div>
              </div>

              {paymentMethodError && <p className="text-red-500 text-sm mt-2">{paymentMethodError}</p>}
            </div>

            <div className="mt-6">
              <button
                onClick={handleConfirmOrder}
                className="w-full uppercase py-3 text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                Confirm Order
              </button>
            </div>
          </aside>
        </div>
      </div>

      <Snackbar message={snackbarMessage} visible={snackbarVisible} />
    </div>
  );
};

export default OrderConfirmPage;
