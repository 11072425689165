import ApiInstance from "../../axios/api.auth";


const createOrder = async (data) => {
  return await ApiInstance.post("user/orders/create", data);
};

const getAllOrder = async () => {
  return await ApiInstance.get("user/orders");
};

const fetchOrder = async (orderId) => {
  return await ApiInstance.get(`user/orders/${orderId}`);
};

const cancelOrder = async (data) => {
  return await ApiInstance.post(`user/orders/cancel-order/`, data);
};

const OrderServices = {
  createOrder,
  getAllOrder,
  fetchOrder,
  cancelOrder
};

export default OrderServices;

