import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CartService from '../services/user/cart.services';

// Fetch all cart items API
export const fetchAllCartAPI = createAsyncThunk(
  'cart/fetchAllCartAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CartService.getAll();
      return response.data.data; // Return the list of items and totalPrice from API
    } catch (error) {
      return rejectWithValue(error.message); // Handle errors
    }
  }
);

// Add item to cart API
export const addToCartAPI = createAsyncThunk(
  'cart/addToCart',
  async ({ productId, quantity, quantitySizeId }, { rejectWithValue }) => {
    try {
      const requestBody = { productId, quantity, quantitySizeId };
      const response = await CartService.create(requestBody);
      return response.data.data; // Returning the updated cart data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update item quantity in cart API
export const updateCartAPI = createAsyncThunk(
  'cart/updateCart',
  async ({ cartItemId, newQuantity }, { rejectWithValue }) => {
    try {
      const requestBody = { cartItemId, newQuantity };
      const response = await CartService.update(requestBody);
      return response.data.data; // Return updated cart data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Remove item from cart API
export const removeFromCartAPI = createAsyncThunk(
  'cart/removeFromCart',
  async (cartItemId, { rejectWithValue }) => {
    try {
      const response = await CartService.destroy(cartItemId);
      return response.data.data; // Return updated cart data after removal
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Cart slice with resetCart reducer
const CartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [], // Array of cart items
    totalPrice: {}, // Total price of items in the cart
    status: 'idle', // Loading status (idle, loading, succeeded, failed)
    error: null, // Error message on failure
  },
  reducers: {
    // Action to reset the cart
    resetCart: (state) => {
      state.items = [];
      state.totalPrice = {};
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all cart items
      .addCase(fetchAllCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllCartAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items || []; // Ensure items array is present
        state.totalPrice = action.payload.totalPrice || {}; // Ensure totalPrice is set
      })
      .addCase(fetchAllCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Add to cart
      .addCase(addToCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addToCartAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items || []; // Ensure items array is present
        state.totalPrice = action.payload.totalPrice || {}; // Ensure totalPrice is set
      })
      .addCase(addToCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update cart
      .addCase(updateCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCartAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items || [];
        state.totalPrice = action.payload.totalPrice || {}; // Ensure totalPrice is set
      })
      .addCase(updateCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Remove from cart
      .addCase(removeFromCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFromCartAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items || [];
        state.totalPrice = action.payload.totalPrice || {}; // Ensure totalPrice is set
      })
      .addCase(removeFromCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetCart } = CartSlice.actions;

export default CartSlice.reducer;

