import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const PinsTableCell = ({ pins }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Check if there are pins data
  if (!pins || pins.length === 0) {
    return <span>No Pins</span>;
  }

  return (
    <div>
      {/* Display pin count and open popup on click */}
      <span
        onClick={handleClickOpen}
        style={{ cursor: "pointer", color: "#1D4ED8" }}
      >
        {pins.length} Pins
      </span>

      {/* Material-UI Dialog with Table */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center", fontSize: "1rem", fontWeight: "400" }}>
          Pin List
        </DialogTitle>

        <DialogContent>
          {/* Material-UI Table */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }}>
              <TableHead className="bg-gray-100 font-bold">
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Pincode</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pins.map((pin, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: pin.available ? "#d1fae5" : "#fee2e2", // Green for available, Red for unavailable
                    }}
                  >
                    <TableCell>{pin.pincode}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "600",
                        color: pin.available ? "#10B981" : "#EF4444", // Green for available, Red for unavailable
                      }}
                    >
                      {pin.available ? "Available" : "Not Available"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PinsTableCell;
