import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CouponService from "../../../services/admin/coupon.services";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";
// import FileUpload from "../../../components/FileUpload/FileUpload";

const initialValues = {
  id: 0,
  title: "",
  code: "",
  amount: "",
  expirationDate: "",
  usageLimit: ""
};

const CouponForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();
  // const BASE_URL = process.env.REACT_APP_API_URL;

  const validationSchema = Yup.object({
    title: Yup.string().required("Coupon Title is required"),
    code: Yup.string().required("Coupon Code is required"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number")
      .max(100, "Amount cannot exceed 100%"),
    expirationDate: Yup.date()
      .required("Expiration Date is required")
      .min(new Date(), "Expiration date cannot be in the past"),
    usageLimit: Yup.number()
      .required("Usage Limit is required")
      .positive("Usage Limit must be a positive number")
      .integer("Usage Limit must be an integer"),
    // attachmentName: Yup.string().required("A file is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      title: values.title,
      code: values.code,
      amount: values.amount,
      expirationDate: values.expirationDate,
      usageLimit: values.usageLimit,
      // attachmentId: values.attachmentId,
      // attachmentName: values.attachmentName,
    };

    setLoading(true);

    try {

      if (!isEmpty(id) && id > 0) {
        const response = await CouponService.update(id, data);
        if (response.data) {
          navigate("/admin/coupon");
          toast.success("Coupon updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await CouponService.create(data);
        if (response.data) {
          navigate("/admin/coupon");
          toast.success("Coupon added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting coupon:", error);
      toast.error(id ? "Failed to update coupon." : "Failed to create coupon.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCouponData = async () => {
    try {
      if (!isEmpty(id) && id > 0) {
        const response = await CouponService.findOne(id);
        if (response.status) {
          const coupon = response.data.data;
          setData({
            id: coupon.id,
            title: coupon.title,
            code: coupon.code,
            amount: coupon.amount,
            expirationDate: coupon.expirationDate.split('T')[0],
            usageLimit: coupon.usageLimit,
            // attachmentId: coupon.attachmentId,
            // attachmentName: coupon.attachmentName,
          });
        } else {
          toast.error("Failed to load coupon data.");
        }
      }
    } catch (error) {
      console.error("Error fetching coupon data:", error);
      toast.error("Failed to load coupon data.");
    }
  };

  useEffect(() => {
    fetchCouponData();
  }, []);

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{id > 0 ? "Edit Coupon" : "Add Coupon"}</h4>
          <h6>{id > 0 ? "Update the coupon details" : "Create a new coupon"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="title">
                        Title <span className="manitory">*</span>
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="code">
                        Coupon code <span className="manitory">*</span>
                      </label>
                      <Field
                        type="text"
                        name="code"
                        className="form-control"
                        id="code"
                      />
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="amount">
                        Amount<span className="manitory">*</span>
                      </label>
                      <Field
                        type="number"
                        name="amount"
                        className="form-control"
                        id="amount"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="expirationDate">
                        Expiration Date <span className="manitory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="expirationDate"
                        className="form-control"
                        id="expirationdate"
                      />
                      <ErrorMessage
                        name="expirationDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="usageLimit">
                        Usage limit <span className="manitory">*</span>
                      </label>
                      <Field
                        type="number"
                        name="usageLimit"
                        className="form-control"
                        id="usageLimit"
                      />
                      <ErrorMessage
                        name="usageLimit"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-submit me-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : id > 0 ? "Update Coupon" : "Add Coupon"}
                    </button>
                    <Link to="/admin/coupon" className="btn btn-cancel">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CouponForm;
