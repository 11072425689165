import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCategories } from '../../../features/category.slice';
import { fetchAllProductsFilter } from '../../../features/product.slice';

const Categories = () => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const { categories, status, error } = useSelector((state) => state.categories);
    const dispatch = useDispatch();

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCategories());
        }
    }, [status, dispatch]);

    const handleCategoryClick = (category, event) => {
        event.preventDefault();
        const filter = {
            categoryId: category
        }
        dispatch(fetchAllProductsFilter(filter));
    };
    return (
        <div className="row">
            <div className="page-header">
                <div className="page-title uppercase">
                    <h5><b>Grains, Food, Vegetables & more</b></h5>
                </div>
            </div>
            <div className="text-center flex flex-col items-center">
                {status === 'loading' && (
                    <div className="flex space-x-4 justify-center">
                        {Array.from({ length: 8 }).map((_, index) => (
                            <div key={index} className="w-24 h-24 bg-gray-300 animate-pulse rounded-md" />
                        ))}
                    </div>
                )}
                {status === 'failed' && (
                    <div>
                        <p>Error: {error}</p>
                        {error.includes('404') && <p>The products you are looking for are not available.</p>}
                    </div>
                )}
                {status === 'succeeded' && (
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={15}
                        centeredSlides={false}
                        // navigation
                        modules={[Navigation]} effect="fade"
                        // modules={[Navigation]}
                        className="relative max-w-[80vw] mx-auto max-h-[25vh]"
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                            1280: {
                                slidesPerView: 7,
                            },
                        }}
                    >
                        <div className="carousel-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ul className="flex flex-wrap justify-center">
                                {categories.length > 0 ? (
                                    categories.map((category) => (
                                        <li className="flex-shrink-0 w-1/2 sm:w-1/3 md:w-1/5 lg:w-1/7" key={category.id}>
                                            <SwiperSlide key={category.id}>
                                                <button
                                                    onClick={(e) => handleCategoryClick(category.id, e)}
                                                    aria-label={`View products in ${category.categoriesName}`}
                                                    className="w-full"
                                                >
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={`${BASE_URL}${category.attachmentName}`}
                                                        alt={category.categoriesName}
                                                        className="mx-auto h-20 w-20 sm:h-20 sm:w-20 lg:h-24 lg:w-24 object-cover rounded-md shadow-lg transition-transform duration-200 ease-in-out hover:scale-105"
                                                    />
                                                    <h6 className="mt-2 text-sm font-semibold">{category.categoriesName}</h6>
                                                </button>
                                            </SwiperSlide>
                                        </li>
                                    ))
                                ) : (
                                    <li className="flex-shrink-0">No categories available.</li>
                                )}
                            </ul>
                        </div>
                    </Swiper>
                )}
            </div>
        </div>
    );
};

export default Categories;


