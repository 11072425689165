/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import BrandService from "../../../services/admin/brand.services"
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";
import FileUpload from "../../../components/FileUpload/FileUpload";



const initialValues = {
  id: 0,
  brandName: '',
  description: '',
  attachmentId: '',
  attachmentName: ''
};
const BrandForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_API_URL;


  const validationSchema = Yup.object({
    brandName: Yup.string().required("Brand Name is required"),
    description: Yup.string().optional(),
    attachmentName: Yup.string().required("A file is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      brandName: values.brandName,
      brandDesc: values.description,
      attachmentId: values.attachmentId,
      attachmentName: values.attachmentName,
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await BrandService.update(id, data);
        if (response.data) {
          navigate("/admin/brand");
          toast.success("Brand updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await BrandService.create(data);
        if (response.data) {
          navigate("/admin/brand");
          toast.success("Brand added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting brand:", error);
      toast.error(id ? "Failed to update brand." : "Failed to create brand.");
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandData = useCallback(async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const result = await BrandService.findOne(id);
        const responseData=result.data.data;
        if (responseData) {        
          setData({
            brandName: responseData.brandName,
            description: responseData.brandDesc,
            attachmentId: responseData.attachmentId,
            attachmentName: responseData.attachmentName
          });
        } else {
          toast.error("Failed to load brand data.");
        }
      }

    } catch (error) {
      console.error("Error fetching brand data:", error);
      toast.error("Failed to load brand data.");
    }
  });

  useEffect(() => {
    fetchBrandData();
  }, []);

  const previewStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    gap: "10px",
    transition: "border-color 0.2s ease",
    borderRadius: "9px",
  };

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Brand" : "Add Brand"}</h4>
          <h6>{(id > 0) ? "Update the brand details" : "Create a new brand"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="brandName">Brand Name <span className="manitory">*</span></label>
                      <Field
                        type="text"
                        name="brandName"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="brandName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Field
                        as="textarea"
                        name="description"
                        placeholder="(Optional)"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Product Image: <span className="manitory">*</span> </label>
                      <FileUpload setUploadRequest={[{
                        attachmentId: data.attachmentId,
                        attachmentName: data.attachmentName
                      }]}
                        setUploadResponse={(response) => {
                          setFieldValue('attachmentId', response.attachmentId);
                          setFieldValue('attachmentName', response.attachmentName);

                        }}
                      />

                      <ErrorMessage name="attachmentName" component="div" style={{ color: 'red' }} />
                    </div>
                  </div>
                  {(!isEmpty(id) && data.attachmentName) ? (
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Old Image:</label>
                        <div style={previewStyle}>
                          <img
                            crossOrigin="anonymous"
                            src={`${BASE_URL}${data.attachmentName}`}
                            alt="Uploaded Preview"
                            style={{ maxWidth: "100%", maxHeight: "100px" }}
                          />
                        </div>
                      </div>
                    </div>) : null
                  }
                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Brand" : "Add Brand"}
                    </button>
                    <Link to={"/admin/brand"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BrandForm;
