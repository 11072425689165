
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchAllAddress, findByIdAddress } from "../../../features/addresses.slice";
import { fetchAllCity, fetchAllDistrict, fetchAllState } from "../../../features/location.slice";
import { isEmpty } from "lodash";
import AddressService from "../../../services/user/address.services";

const AddressForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const { stateData, districtData, cityData, statusState, statusDistrict, statusCity, error } = useSelector((state) => state.location);

  const [initialValues, setInitialValues] = useState({
    id: 0,
    fullName: "",
    phone: "",
    phoneAlter: "",
    pinCode: "",
    state: "",
    district: "",
    city: "",
    houseNo: "",
    landmark: "",
    typeOfAddress: "",
  });


  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    phone: Yup.string().matches(/^\d{10}$/, "Invalid phone number").required("Phone number is required"),
    phoneAlter: Yup.string().matches(/^\d{10}$/, "Invalid alternative phone number").nullable(),
    pinCode: Yup.string().matches(/^\d{6}$/, "Invalid pin code").required("Pincode is required"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
    city: Yup.string().required("City is required"),
    typeOfAddress: Yup.string().required("Address type is required"),
  });


  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await AddressService.update(id, values);
        if (response.data) {
          navigate("/user/address");
          toast.success("Address updated successfully");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await AddressService.create(values);
        if (response.data) {
          navigate("/user/address");
          toast.success("Address added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
      await dispatch(fetchAllAddress()).unwrap();
    } catch (error) {
      console.error("Error submitting address:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (statusState === "idle") {
      dispatch(fetchAllState());
    }
  }, [dispatch, statusState]);


  const handleStateChange = (e, setFieldValue) => {
    const selectedState = e.target.value;
    setFieldValue("state", selectedState);
    setFieldValue("district", "");
    setFieldValue("city", "");
    if (selectedState) {
      dispatch(fetchAllDistrict({ stateName: selectedState }));
    }
  };

  const handleDistrictChange = (e, setFieldValue) => {
    const selectedDistrict = e.target.value;
    setFieldValue("district", selectedDistrict);
    setFieldValue("city", "");
    if (selectedDistrict) {
      dispatch(fetchAllCity({ districtName: selectedDistrict }));
    }
  };


  useEffect(() => {
    if (id > 0) {
      const fetchAddressDetails = async () => {
        try {
          const response = await dispatch(findByIdAddress(id)).unwrap();
          setInitialValues(response);
          if (response.state) dispatch(fetchAllDistrict({ stateName: response.state }));
          if (response.district) dispatch(fetchAllCity({ districtName: response.district }));
        } catch (error) {
          console.error("Failed to fetch address:", error);
          toast.error("Failed to load address details.");
        }
      };
      fetchAddressDetails();
    }
  }, [dispatch, id]);


  if (statusState === "loading" || statusDistrict === "loading" || statusCity === "loading") return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-wrapper ms-0 bg-gray-50">
      <div className="content px-6 py-8">
        <div className="page-header">
          <div className="page-title">
            <h4>{id > 0 ? "Edit Address" : "Add New Address"}</h4>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row">

                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="fullName">Full Name <span className="manitory">*</span></label>
                        <Field type="text" name="fullName" className="form-control" id="fullName" />
                        <ErrorMessage name="fullName" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="phone">Phone Number <span className="manitory">*</span></label>
                        <Field type="text" name="phone" className="form-control" id="phone" />
                        <ErrorMessage name="phone" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="phoneAlter">Alternative Phone Number</label>
                        <Field type="text" name="phoneAlter" className="form-control" id="phoneAlter" />
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="pinCode">PinCode <span className="manitory">*</span></label>
                        <Field type="text" name="pinCode" className="form-control" id="pinCode" />
                        <ErrorMessage name="pinCode" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="state">State <span className="manitory">*</span></label>
                        <Field as="select" name="state" className="form-control" id="state" value={values.state} onChange={(e) => handleStateChange(e, setFieldValue)}>
                          <option value="">Select State</option>
                          {stateData.map((item, index) => (
                            <option key={index} value={item.state_code}>{item.stateName}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="state" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="district">District <span className="manitory">*</span></label>
                        <Field as="select" name="district" className="form-control" id="district" value={values.district} onChange={(e) => handleDistrictChange(e, setFieldValue)}>
                          <option value="">Select District</option>
                          {districtData.map((item, index) => (
                            <option key={index} value={item.districtCode}>{item.distName}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="district" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="city">Area <span className="manitory">*</span></label>
                        <Field as="select" name="city" className="form-control" id="city" value={values.city}>
                          <option value="">Select City</option>
                          {cityData.map((item, index) => (
                            <option key={index} value={item.place}>{item.place}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="city" component="div" className="text-danger" />
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="houseNo">House No</label>
                        <Field type="text" name="houseNo" className="form-control" id="houseNo" />
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="landmark">Landmark</label>
                        <Field type="text" name="landmark" className="form-control" id="landmark" />
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="typeOfAddress">Address Type <span className="manitory">*</span></label>
                        <Field as="select" name="typeOfAddress" className="form-control" id="typeOfAddress">
                          <option value="">Select Address Type</option>
                          <option value="HOME">HOME</option>
                          <option value="OFFICE">OFFICE</option>
                        </Field>
                        <ErrorMessage name="typeOfAddress" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-submit me-2" disabled={loading}>
                      {loading ? "Saving..." : id > 0 ? "Update Address" : "Add Address"}
                    </button>
                    <Link to="/user/address" className="btn btn-cancel">Cancel</Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;

