/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useCallback} from "react";
import CouponService from "../../../services/admin/coupon.services";
import DataTable from "../../../components/Datatable/DataTable"
import { Link } from "react-router-dom";
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { format } from 'date-fns';
import { PencilIcon, PowerIcon, TrashIcon } from "@heroicons/react/20/solid";


const Coupon = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  // const BASE_URL = process.env.REACT_APP_API_URL;
  

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    { field: 'title', headerName: 'Title', width: 200 },
    // {
    //   field: 'attachmentName',
    //   headerName: 'Image',
    //   width: 120,
    //   renderCell: (params) => {
    //     const imageUrl = `${BASE_URL}${params.value}?t=${new Date().getTime()}`;
    //     const imageExists = params.value && params.value !== "";
    
    //     return imageExists ? (
    //       <img
    //         className="product-img"
    //         crossOrigin="anonymous"
    //         src={imageUrl}
    //         alt="Product"
    //         style={{ width: '50px', height: '50px' }}
    //       />
    //     ) : (
    //       <img
    //         crossOrigin="anonymous"
    //         className="product-img"
    //         src="../../../assets/img/icons/upload.svg" // Placeholder if no image is available
    //         alt="No Image Available"
    //         style={{ width: '50px', height: '50px' }}
    //       />
    //     );
    //   },
    // },    
    { field: 'code', headerName: 'Code', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    {
      field: 'expirationDate',
      headerName: 'Validity',
      width: 100,
      renderCell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'), 
    },
    { field: 'usageLimit', headerName: 'Limit', width: 100 },
    { field: 'isActive', headerName: 'Status', width: 100 ,
      renderCell: (params) => {
        const status = params.value ? 'Active' : 'Inactive';
        const badgeClass = params.value ? 'badge' : 'badge'; 

        const badgeStyle = {
          padding: '5px 10px',
          fontSize: '12px',
          backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
          color: params.value ? '#006400' : '#8B0000', 
        };

        return (
          <span className={badgeClass} style={badgeStyle}>
            {status}
          </span>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        const isDeactivated = !params.row.isActive; 
        return (
          <div className="flex mt-3">
            <Link className="me-3" to={`/admin/coupon/${params.row.id}`}>
              <PencilIcon className="h-5 w-5 text-green-600" />
            </Link>
            <Link
              className="me-3 confirm-text"
              onClick={() => handleDelete(params.row.id)}
            >
              <TrashIcon className="h-5 w-5 text-red-600" />
            </Link>
            <button
              className="me-3"
              onClick={() => handleDeactivate(params.row.id)}
              disabled={isDeactivated || loading} 
            >
              <PowerIcon className="h-5 w-5 text-orange-500" />
            </button>
          </div>
        );
      },
    }
    
  ];

  const fetchData = useCallback(async (page, pageSize,  searchTerm) => {
    setLoading(true);
    try {
      const response = await CouponService.findAll(page, pageSize,  searchTerm);
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize,  searchTerm);
  }, [paginationModel, fetchData,  searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await CouponService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The coupon has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting coupon: ' + error);
      console.error('Error deleting coupon:', error);
    }
  };

  const handleDeactivate = async (id) => {
    setLoading(true); 
    try {
      await CouponService.deactivate(id);
      toast.success("Coupon deactivated successfully!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deactivating coupon: ' + error);
      console.error('Error deactivating coupon:', error);
    } finally {
      setLoading(false); 
    }
  };
  


  return (
    <>
    <div className="content">
  <div className="page-header">
    <div className="page-title">
      <h4>Coupon</h4>
      <h6>Manage Coupon</h6>
    </div>
    <div className="page-btn">
      <Link  to={"add"} className="btn btn-added" data-bs-toggle="modal" data-bs-target="#addpayment"><img src="../../../assets/img/icons/plus.svg" alt="img" className="me-1" />Add Coupon</Link>
    </div>
  </div>
  <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                    <input type="search" value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
            <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </div>
</div>
   </>
  )
}

export default Coupon
