/* eslint-disable jsx-a11y/img-redundant-alt */
import { StarIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const ProductList = ({ product }) => {
  const percentage = product.discount_master.percentage;
  const originalPrice = product.price;
  // Calculate the discounted price
  const BASE_URL = process.env.REACT_APP_API_URL;

  const discountPercentage = originalPrice - (originalPrice * (percentage / 100));

  return (
    <div className="lg:w-1/3 md:w-1/3 sm:w-1/2 w-full mb-4">
      <div className="product-card flex flex-col border rounded-lg shadow-md overflow-hidden bg-white transform transition-all duration-300 hover:scale-105 hover:shadow-lg">

        <Link to={`/user/product-details/${product.id}`} className="block">
          <div className="product-card-img relative">
            <img
              crossOrigin="anonymous"
              src={`${BASE_URL}${product.attachmentName}`}
              alt={`Image of ${product.productName}`}
              className="h-48 w-full object-cover transition-all duration-300 transform hover:scale-105"
            />
            <h6
              className={`absolute top-2 left-2 text-white text-xs px-2 py-1 rounded-full animate-bounce ${product.stock !== 0 ? 'bg-green-500' : 'bg-red-500'}`}
            >
              {product.stock !== 0 ? "In Stock" : "Out of Stock"}
            </h6>

            <div className="check-product absolute top-0 right-0 p-2 bg-green-500 rounded-full">
              <i className="fa fa-check text-white" />
            </div>
          </div>
        </Link>

        <div className="product-card-content p-3 md:p-6">
          <h5 className="category text-sm text-gray-500">{product.categories_master.categoriesName}</h5>

          <Link to={`/user/product-details/${product.id}`} className="text-lg font-semibold text-gray-800 hover:underline mt-2 block">
            {product.productName}
          </Link>

          <div className="mt-2 flex items-center justify-start space-x-2">
            <div className="flex items-center space-x-1">
              <span className="flex items-center justify-center bg-green-500 text-white badge text-xs">
                {4.2}
                <StarIcon className="h-3 w-3 ml-1 text-white" />
              </span>
              <span className="text-sm text-gray-700">{22} Ratings</span>
              <span className="text-sm text-gray-700">& {33} Reviews</span>
            </div>
          </div>

          <div className="mt-2 flex items-center justify-start">
            <h6 className="price text-xl font-bold text-black space-x-1">
              ₹{discountPercentage.toFixed(2)}
              <span className="line-through text-sm text-gray-400">₹{product.price.toFixed(2)}</span>
              <span className="discount font-semibold text-sm text-green-500">{Math.round(percentage)}% off</span>
            </h6>
          </div>
          <button
            className="w-full uppercase py-2 mt-2 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          // onClick={() => onAddToCart(data)}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>

  );
};

export default ProductList;
