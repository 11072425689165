import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from 'react-router-dom';
import { isEmpty } from "validator";
import MerchantService from "../../../services/admin/merchant.services";
import { toast } from "react-toastify";

const MerchantView = () => {
    const { id } = useParams();
    const [merchantDetails, setMerchantDetails] = useState({});
    const BASE_URL = process.env.REACT_APP_API_URL;

    // Fetch merchant details
    const fetchMerchantData = useCallback(async () => {
        if (isEmpty(id) || id <= 0) return;
        try {
            const response = await MerchantService.findOne(id);
            if (response.status) {
                const data = response.data.data;
                setMerchantDetails({
                    shopName: data.shopName,
                    branchCode: data.branchCode,
                    branchType: data.branchType,
                    attachmentId: data.attachmentId,
                    attachmentName: data.attachmentName,
                    createdAt: new Date(data.createdAt).toLocaleDateString(),
                    status: data.status ? 'Active' : 'Inactive',
                    district: data.district,
                    state: data.state,
                    pincode: data.pincode,
                    shopAddress: data.shopAddress,
                    emailId: data.emailId,
                    fullname: `${data.firstName} ${data.lastName}`,
                    gstNo: data.gstNo,
                    merchantType: data.merchantType,
                    mobile: data.mobile,
                    id: data.id,
                });
            } else {
                toast.error("Failed to load merchant data.");
            }
        } catch (error) {
            toast.error("Failed to load merchant data.");
        }
    }, [id]);

    // Fetch data on mount
    useEffect(() => {
        fetchMerchantData();
    }, [fetchMerchantData]);

    return (
        <div className="content">
            <div className="page-header">
                <div className="page-title">
                    <h4>Merchants View</h4>
                    <h6>Full details of a Merchant</h6>
                </div>
            </div>
            <div className="row">
                {/* Merchant Details */}
                <div className="col-lg-8 col-sm-12">
                    <div className="card">
                        <div className="card-header font-semibold d-flex justify-content-center align-items-center">
                            Merchant Details
                        </div>
                        <div className="card-body">
                            <div className="productdetails">
                                <ul className="product-bar">
                                    {/* <li>
                                        <h4>Shop Image</h4>
                                        <h6>
                                            <div className="card-header d-flex justify-content-center align-items-center">
                                                <img
                                                    src={`${BASE_URL}${merchantDetails.attachmentName}` || "../../../assets/img/customer/customer5.jpg"}
                                                    alt="shop-Image"
                                                    crossOrigin="anonymous"
                                                    className="w-16 h-16 sm:w-32 sm:h-32 lg:w-48 lg:h-48 bg-red-500"
                                                />
                                            </div>
                                        </h6>
                                    </li> */}
                                    <li>
                                        <h4>Full Name</h4>
                                        <h6>{merchantDetails.fullname}</h6>
                                    </li>
                                    <li>
                                        <h4>Shop Name</h4>
                                        <h6>{merchantDetails.shopName}</h6>
                                    </li>
                                    <li>
                                        <h4>Merchant Type</h4>
                                        <h6>{merchantDetails.merchantType}</h6>
                                    </li>
                                    <li>
                                        <h4>Branch Code</h4>
                                        <h6>{merchantDetails.branchCode}</h6>
                                    </li>
                                    <li>
                                        <h4>Branch Type</h4>
                                        <h6>{merchantDetails.branchType}</h6>
                                    </li>
                                    <li>
                                        <h4>E-mail</h4>
                                        <h6>{merchantDetails.emailId}</h6>
                                    </li>
                                    <li>
                                        <h4>Contact No</h4>
                                        <h6>{merchantDetails.mobile}</h6>
                                    </li>
                                    <li>
                                        <h4>State</h4>
                                        <h6>{merchantDetails.state}</h6>
                                    </li>
                                    <li>
                                        <h4>District</h4>
                                        <h6>{merchantDetails.district}</h6>
                                    </li>
                                    <li>
                                        <h4>Shop Address</h4>
                                        <h6>{merchantDetails.shopAddress}</h6>
                                    </li>
                                    <li>
                                        <h4>Pincode</h4>
                                        <h6>{merchantDetails.pincode}</h6>
                                    </li>
                                    <li>
                                        <h4>GST NO</h4>
                                        <h6>{merchantDetails.gstNo}</h6>
                                    </li>
                                    <li>
                                        <h4>Status</h4>
                                        <h6>{merchantDetails.status}</h6>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <Link to={"/admin/merchants"} className="btn btn-cancel" type="reset">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex flex-col justify-center items-center text-center">
                                <img
                                    src={`${BASE_URL}${merchantDetails.attachmentName}`}
                                    alt=""
                                    crossOrigin="anonymous"
                                    className="mb-2"
                                />
                                <h4 className="font-semibold">{merchantDetails.attachmentName}</h4>
                                <h6 className="text-gray-500">581kb</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchantView;

