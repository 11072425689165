/* eslint-disable react/react-in-jsx-scope */
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuItems,
    MenuItem,
    MenuButton,
    Menu,
    Transition,
} from "@headlessui/react";
import {
    Bars3Icon,
    XMarkIcon,
    BuildingStorefrontIcon,
    Squares2X2Icon,
    RectangleGroupIcon,
    CubeIcon,
    TagIcon,
    CircleStackIcon,
    UserGroupIcon,
    UsersIcon,
    PowerIcon,
    UserCircleIcon,
    MapPinIcon,
    ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import {
    ChevronDownIcon,
    PhoneIcon,
    PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../../../features/auth.slice";
import { selectCurrentUser } from "../../../../features/auth.slice";

const reports = [
    {
        name: "Users",
        description: "Find All users",
        href: "users",
        icon: UsersIcon,
    },   

];
const shoppings = [
    {
        name: "Delivery Location",
        description: "Mapping delivery location",
        href: "location",
        icon: MapPinIcon,
    },
    {
        name: "My Orders",
        description: "All shopping orders",
        href: "order",
        icon: ShoppingBagIcon,
    }

];

const settings = [
    {
        name: "Tax",
        description: "Get a better understanding of your tax",
        href: "tax",
        icon: CircleStackIcon,
    },
    {
        name: "Discount",
        description: "Get a better understanding of your merchant",
        href: "discount",
        icon: TagIcon,
    },
    {
        name: "Unit",
        description: "Get a better understanding of your merchant",
        href: "unit",
        icon: UserGroupIcon,
    },
    {
        name: "Coupon",
        description: "Get a better understanding of your merchant",
        href: "coupon",
        icon: TagIcon,
    },
    {
        name: "Banner",
        description: "Get a better understanding of your merchant",
        href: "banner",
        icon: TagIcon,
    },
]
const products = [
    {
        name: "Categories",
        description: "Get a better understanding of your merchant",
        href: "categories",
        icon: Squares2X2Icon,
    },
    {
        name: "SubCategories",
        description: "Get a better understanding of your merchant",
        href: "sub-categories",
        icon: RectangleGroupIcon,
    },
    {
        name: "Products",
        description: "Get a better understanding of your merchant",
        href: "products",
        icon: CubeIcon,
    },
    {
        name: "Brand",
        description: "Get a better understanding of your merchant",
        href: "brand",
        icon: TagIcon,
    },
    // {
    //     name: "Expanse",
    //     description: "Get a better understanding of your merchant",
    //     href: "expanse",
    //     icon: ChartPieIcon,
    // },
    // {
    //     name: "Expanse Categories",
    //     description: "Get a better understanding of your merchant",
    //     href: "expanse-categories",
    //     icon: ChartPieIcon,
    // },
  
   
];


const merchants = [
    {
        name: "Merchants",
        description: "Get a better understanding of your merchant",
        href: "merchants",
        icon: BuildingStorefrontIcon,
    }
]



const callsToAction = [
    { name: "Watch demo", href: "#", icon: PlayCircleIcon },
    { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const userNavigation = [
    { name: "MY PROFILE", href: "profile", icon: UserCircleIcon },
    { name: "SIGNOUT", href: "login", icon: PowerIcon },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function Header() {
    // const items = useSelector((state) => state.cart.items);
    const itemCount = 0;
    console.log(itemCount, "itemCount")
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(reset());
        console.log('Logout successfully');
        navigate('/');
    };

    return (
        <header className="bg-white">
            <nav
                className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <Link to="#" className="-m-1.5 p-1.5">
                    <span className="font-semibold">KGP CENTER</span>
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <Link
                        to="/admin"
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        Dashboard
                    </Link>
                    <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Merchants
                            <ChevronDownIcon
                                className="h-4 w-4 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-xs overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-gray-900/10 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {merchants.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-4 rounded-md p-2 text-sm leading-5 hover:bg-gray-50"
                                    >
                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-md bg-gray-100 group-hover:bg-indigo-100">
                                            <item.icon
                                                className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex-auto">
                                            <Link
                                                to={item.href}
                                                className="block font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-500 text-xs">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>
                    <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Products
                            <ChevronDownIcon
                                className="h-4 w-4 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-xs overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-gray-900/10 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {products.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-4 rounded-md p-2 text-sm leading-5 hover:bg-gray-50"
                                    >
                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-md bg-gray-100 group-hover:bg-indigo-100">
                                            <item.icon
                                                className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex-auto">
                                            <Link
                                                to={item.href}
                                                className="block font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-500 text-xs">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>

                    </Popover>
                    <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Settings
                            <ChevronDownIcon
                                className="h-4 w-4 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-xs overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-gray-900/10 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {settings.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-4 rounded-md p-2 text-sm leading-5 hover:bg-gray-50"
                                    >
                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-md bg-gray-100 group-hover:bg-indigo-100">
                                            <item.icon
                                                className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex-auto">
                                            <Link
                                                to={item.href}
                                                className="block font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-500 text-xs">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>

                    </Popover>
                    <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Reports
                            <ChevronDownIcon
                                className="h-4 w-4 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-xs overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-gray-900/10 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {reports.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-4 rounded-md p-2 text-sm leading-5 hover:bg-gray-50"
                                    >
                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-md bg-gray-100 group-hover:bg-indigo-100">
                                            <item.icon
                                                className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex-auto">
                                            <Link
                                                to={item.href}
                                                className="block font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-500 text-xs">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>
                    <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Shopping
                            <ChevronDownIcon
                                className="h-4 w-4 flex-none text-gray-400"
                                aria-hidden="true"
                            />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-xs overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-gray-900/10 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {shoppings.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-4 rounded-md p-2 text-sm leading-5 hover:bg-gray-50"
                                    >
                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-md bg-gray-100 group-hover:bg-indigo-100">
                                            <item.icon
                                                className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex-auto">
                                            <Link
                                                to={item.href}
                                                className="block font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-500 text-xs">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>
                </PopoverGroup>

                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <div className="ml-2 uppercase text-center">
                        <div className="text-xs font-medium text-gray-900">
                            <Link to="profile" className="flex items-center">
                                {currentUser.openId}
                                <span aria-hidden="true" className="ml-1">&rarr;</span>
                            </Link>
                        </div>
                        <small className="text-xs text-gray-500">{currentUser.role}</small>
                    </div>
                    <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                            <div>
                                <MenuButton className="flex items-center justify-center rounded-full bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src={currentUser.profilePicture || require("../../../../assets/logo.png")}
                                        alt="Profile"
                                    />
                                </MenuButton>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <MenuItems className="absolute flex-auto right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-100 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userNavigation.map((item) => (
                                        <MenuItem key={item.name}>
                                            {({ close }) => (
                                                <Link
                                                    to={item.href}
                                                    className="flex bold uppercase px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 transition items-center justify-start"
                                                    onClick={item.name === "SIGNOUT" ? handleLogout : undefined}
                                                    aria-label={`Go to ${item.name}`}
                                                >
                                                    <item.icon className="h-5 w-5 mr-4" aria-hidden="true" /> {/* Added margin-right for spacing */}
                                                    {item.name}
                                                </Link>
                                            )}
                                        </MenuItem>
                                    ))}
                                </MenuItems>

                            </Transition>
                        </Menu>

                    </div>
                </div>
            </nav>
            <Dialog
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="#" className="-m-1.5 p-1.5">

                            <img className="h-8 w-8 rounded-full"
                                src={currentUser.profilePicture || require("../../../../assets/logo.png")}
                                alt="profile"
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                Product
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? "rotate-180" : "",
                                                        "h-5 w-5 flex-none"
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2">
                                                {[...products, ...callsToAction].map((item) => (
                                                    <DisclosureButton
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </DisclosureButton>
                                                ))}
                                            </DisclosurePanel>
                                        </>
                                    )}
                                </Disclosure>
                                <Link
                                    to="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Features
                                </Link>
                                <Link
                                    to="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Marketplace
                                </Link>
                            </div>
                            <div className="py-6">
                                <Link
                                    to="/login"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    <span as="a" onClick={handleLogout} > Log out</span>

                                </Link>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}
