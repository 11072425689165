
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './include/Header';
import Footer from './include/Footer';

function UserLayout() {
  return (
    <>
      <div className="flex flex-col h-screen main-wrapper">
        <div className="header">
        <Header />
        </div>
        <div className="flex flex-1 overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            <Outlet />
          </main>         
        </div>
        <Footer/>
      </div>

    </>
  );
}

export default UserLayout;
