import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import DiscountService from '../../../services/admin/discount.services';
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";

const initialValues = {
  id: 0,
  title: '',
  percentage: '',
  startDate: '',
  endDate: '',
  status: ''
};

const DiscountForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    percentage: Yup.number().required('Percentage is required'),
    startDate: Yup.date().required('Start Date is required').nullable(),
    endDate: Yup.date().required('End Date is required').nullable(),
    status: Yup.boolean().required('Status is required')
  });

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      title: values.title,
      percentage: values.percentage,
      startDate: values.startDate,

      endDate: values.endDate,
      status: values.status === 'true'
    };

    setLoading(true);
    try {
      if (!isEmpty(id) && id > 0) {
        const response = await DiscountService.update(id, data);
        if (response.data) {
          navigate("/admin/discount");
          toast.success("Discount updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await DiscountService.create(data);
        if (response.data) {
          navigate("/admin/discount");
          toast.success("Discount added successfully!");

        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting discount:", error);
      toast.error(id ? "Failed to update discount." : "Failed to create discount.");
    } finally {
      setLoading(false);
    }
  };

  const fetchDiscountData = async () => {
    try {
      if (!isEmpty(id) && id > 0) {
        const response = await DiscountService.findOne(id);
        if (response.status) {
          const data = response.data.data;
          setData({
            id: data.id,
            title: data.title,
            percentage: data.percentage,
            startDate: data.startDate.split('T')[0],
            endDate: data.endDate.split('T')[0],
            status: data.status ? 'true' : 'false'
          });
        } else {
          toast.error("Failed to load discount data.");
        }
      }
    } catch (error) {
      console.error("Error fetching discount data:", error);
      toast.error("Failed to load discount data.");
    }
  };

  useEffect(() => {
    fetchDiscountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Discount" : "Add Discount"}</h4>
          <h6>{(id > 0) ? "Update the discount details" : "Create a new discount"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="title">Title <span className="manitory">*</span></label>
                      <Field type="text" name="title" className="form-control rounded" />
                      <ErrorMessage name="title" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="percentage">Percentage(%) <span className="manitory">*</span></label>
                      <Field type="text" name="percentage" className="form-control rounded" />
                      <ErrorMessage name="percentage" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="startDate">Start Date <span className="manitory">*</span></label>
                      <Field type="date" name="startDate" className="form-control rounded" />
                      <ErrorMessage name="startDate" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="endDate">End Date <span className="manitory">*</span></label>
                      <Field type="date" name="endDate" className="form-control rounded" />
                      <ErrorMessage name="endDate" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="status">Status <span className="manitory">*</span></label>
                      <Field name="status" as="select" className="form-control rounded">
                        <option value="">Select</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Field>
                      <ErrorMessage name="status" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Discount" : "Add Discount"}
                    </button>
                    <Link to={"/admin/discount"} className="btn btn-cancel">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default DiscountForm;
