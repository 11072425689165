

import React, { useState } from "react";
import { AiOutlineCloudUpload, AiOutlineDelete } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import AttachmentService from "../../services/admin/attachment.services";
import { isEmpty } from "lodash";

const FileUpload = ({ setUploadRequest, setUploadResponse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [attachmentId, setAttachmentId] = useState();
  const [iPreview, setIPreview] = useState(null);
  const [imageRefreshKey, setImageRefreshKey] = useState(0); // New state for refreshing image
  const BASE_URL = process.env.REACT_APP_API_URL;

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      setErrorMessage("A file is required.");
      return;
    }

    const allowedFormats = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedFormats.includes(file.type)) {
      setErrorMessage("Unsupported file format. Please upload a JPEG, PNG, or GIF.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setErrorMessage("File size is too large. Maximum size is 2MB.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    setIPreview(null);
    try {
      const formData = new FormData();
      formData.append("attachmentType", "ITM");
      formData.append("file", file);

      if ((!isEmpty(setUploadRequest.attachmentId)) && (!isEmpty(setUploadRequest.attachmentName))) {
        formData.append("attachmentId", setUploadRequest.attachmentId);
        formData.append("fileName", setUploadRequest.attachmentName);
      }
      // Simulate a delay (you can remove this in production)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await AttachmentService.createAndUpdate(formData);
      const attachmentData = response.data.data;
      const attachmentUrl = `${BASE_URL}${attachmentData.attachmentName}`;
      setAttachmentId(attachmentData.attachmentId);
      setIPreview(attachmentUrl);
      setUploadResponse(attachmentData);
      setSuccessMessage((!isEmpty(setUploadRequest.attachmentId)) ? "File uploaded & updated successfully." : "File uploaded successfully.");

      // Increment the image refresh key after upload
      setImageRefreshKey(prev => prev + 1);
    } catch (error) {
      const message = error.response?.data?.message || "Upload failed.";
      setErrorMessage(message);
      console.error("Upload failed:", message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (attachmentId) {
      await AttachmentService.destroy({ attachmentId: attachmentId });
      setErrorMessage("");
      setIPreview(null);
      setSuccessMessage("File deleted successfully.");

      // Increment the image refresh key after delete
      setImageRefreshKey(prev => prev + 1);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
    },
  });

  return (
    <>
      <div
        {...getRootProps({
          className: "dropzone image-upload",
          style: dropzoneStyle,
        })}
      >
        <input {...getInputProps()} />
        <AiOutlineCloudUpload size={"40px"} color="orange" opacity={0.9} />
        <p>
          {isLoading ? "Uploading..." : "Drag & drop some files here, or click to select files"}
        </p>
      </div>

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      {/* Image Preview */}
      {iPreview && (
        <>         
          <label>Image Preview:</label>
          <div style={previewStyle}>
            <img
              crossOrigin="anonymous"
              src={`${iPreview}?t=${imageRefreshKey}`} // Use image refresh key
              alt="Uploaded Preview"
              style={{ maxWidth: "100%", maxHeight: "100px" }}
            />
            <button
              type="button"
              onClick={handleDelete}
              style={deleteButtonStyle}
            >
              <AiOutlineDelete size={"25px"} color="#fffff" opacity={0.9} />
            </button>
          </div>      

        </>
      )}
    </>
  );
};

const dropzoneStyle = {
  height: "125px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontSize: "13px",
  gap: "10px",
  borderRadius: "9px",
  border: ".1rem solid rgb(216, 214, 214)",
  padding: "10px",
  color: "#42a5f5",
  fontWeight: 300,
  marginBottom: "20px",
  transition: "border-color 0.2s ease",
};

const previewStyle = {
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
  gap: "10px",
  transition: "border-color 0.2s ease",
  borderRadius: "9px",
};

const deleteButtonStyle = {
  padding: "5px 10px",
  backgroundColor: "#ff4d4d",
  border: "none",
  borderRadius: "5px",
  color: "#fff",
  cursor: "pointer",
};

export default FileUpload;

