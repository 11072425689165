import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Link } from 'react-router-dom';
import { fetchAllAddress } from '../../../features/addresses.slice';

const AddressView = () => {
  const dispatch = useDispatch();


  const { addresses, status, error } = useSelector((state) => state.address);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAllAddress());
    }
  }, [status, dispatch]);


  return (
    <div className="page-wrapper ms-0">
      <div className="content px-6 py-8">
        <div className="bg-white rounded-lg p-4 border-4 border-transparent bg-gradient-to-r from-green-200 via-purple-200 to-pink-200">
        <h4 className="text-2xl font-semibold text-gray-800 mb-6">Manage Your Addresses</h4>

       
          {status === 'loading' && <p>Loading addresses...</p>}

       
          {status === 'failed' && <p>{error || 'Failed to load addresses.'}</p>}

          <div className="address-cards-container flex flex-col items-center gap-4">
      
            <Link to="add" className="self-end bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600">
              Add New Address
            </Link>

            {status === 'succeeded' && (
              <>
                {  addresses.length > 0 ? (
                  addresses.map((addressItem, index) => (
                    <div
                      key={index}
                      className="address-card p-4 mb-3 rounded-lg bg-white max-w-xl w-full shadow-md"
                    >
                      <div className="flex justify-between items-center">
                      
                        <h3 className="font-semibold text-lg">
                          {addressItem.fullName}
                          <span className="ml-2 px-3 py-1 text-xs font-semibold badge text-gray-500 bg-gray-200 p-1 border-b-2 border-gray-600">
                            {addressItem.typeOfAddress}
                          </span>
                          {/* <span
                            className={` ml-2 px-3 py-1 text-xs font-semibold badge ${addressItem.isActive ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                              }`}>
                            {addressItem.isActive ? 'ACTIVE' : 'INACTIVE'}
                          </span> */}
                        </h3>

               
                        <Dropdown id={addressItem.id} />
                      </div>

                  
                      <p className="mt-2 text-gray-700">
                        {addressItem.houseNo}, {addressItem.landmark}, {addressItem.city}, {addressItem.district}, {addressItem.state}, {addressItem.pinCode}
                      </p>
                      <p className="font-bold text-xs mt-2">
                        {addressItem.phone}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No addresses available. Add a new one to get started!!</p>
                )}
              </>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default AddressView;
