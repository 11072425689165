import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductsService from '../services/user/products.services'; // Ensure this path is correct

// Async thunk to fetch all products
export const fetchAllProducts = createAsyncThunk(
  'products/fetchAll',
  async () => {
    try {
      const response = await ProductsService.getAllProduct();
      // console.log("Fetched Products: ", response.data); // Logging for debugging
      return response.data.data; // Ensure response data is in the expected format
    } catch (error) {
      console.error('Error fetching products: ', error);
      throw new Error('Failed to fetch products');
    }
  }
);
export const fetchAllProductsFilter = createAsyncThunk(
  'products/fetchAllFilter',
  async (filter) => {
    try {
      const response = await ProductsService.getAllProductFilter(filter);
      // console.log("Fetched Products: ", response.data); // Logging for debugging
      return response.data.data; // Ensure response data is in the expected format
    } catch (error) {
      console.error('Error fetching products: ', error);
      throw new Error('Failed to fetch products');
    }
  }
);
// Async thunk to fetch a single product by ID
export const fetchProductById = createAsyncThunk(
  'product/fetchById',
  async (productId) => {
    try {
      const response = await ProductsService.findOneByProductId(productId);
      // console.log("Fetched Product by ID: ", response.data); // Logging for debugging
      return response.data.data.product; // Ensure response data is in the expected format
    } catch (error) {
      console.error('Error fetching product by ID: ', error);
      throw new Error('Failed to fetch product');
    }
  }
);
// Async thunk to fetch merchant by productId
// export const fetchMerchantByProductId = createAsyncThunk(
//   'product/fetchMerchantByProductId',
//   async (productId) => {
//     try {
//       const response = await ProductsService.findAllMerchantByProductId(productId);
//       // console.log("Fetched Product by ID: ", response.data); // Logging for debugging
//       return response.data.data.merchants; // Ensure response data is in the expected format
//     } catch (error) {
//       console.error('Error fetching product by ID: ', error);
//       throw new Error('Failed to fetch product');
//     }
//   }
// );

const productSlice = createSlice({
  name: 'products',
  initialState: {
    merchants: [], // For storing all products
    items: [], // For storing all products
    product: {}, // For storing a single product
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetchAllProducts    
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.items = action.payload; // Store fetched products in state
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message; // Store the error message
      })
       // Handle fetchAllProductsFilter 
      .addCase(fetchAllProductsFilter.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProductsFilter.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.items = action.payload; // Store fetched products in state
      })
      .addCase(fetchAllProductsFilter.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message; // Store the error message
      })

      // Handle fetchProductById
      .addCase(fetchProductById.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.product = action.payload; // Store fetched product in state
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message; // Store the error message
      })
      // // Handle fetchMerchantByProductId
      // .addCase(fetchMerchantByProductId.pending, (state) => {
      //   state.status = 'loading';
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchMerchantByProductId.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.loading = false;
      //   state.merchants = action.payload; // Store fetched product in state
      // })
      // .addCase(fetchMerchantByProductId.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.loading = false;
      //   state.error = action.error.message; // Store the error message
      // });
  },
});

export default productSlice.reducer;
