import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth.slice";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { removeFromCartAPI, updateCartAPI, fetchAllCartAPI } from "../../../features/cart.slice";
import Snackbar from "../../../components/Snackbar/Snackbar";
import DeliveryService from "../../../services/user/delivery.services";
import { MapPinIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";

const CartPage = () => {
    const dispatch = useDispatch();
    const { items, totalPrice, status } = useSelector((state) => state.cart);
    const user = useSelector(selectCurrentUser);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [delivery, setDelivery] = useState(0);
    const [pincode, setPinCode] = useState("");
    const [success, setSuccess] = useState("");
    const [err, setErr] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

        if (status === "idle") {
            dispatch(fetchAllCartAPI());
        }
    }, [dispatch, status]);


    useEffect(() => {
        if (snackbarVisible) {
            const timer = setTimeout(() => setSnackbarVisible(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [snackbarVisible]);

    useEffect(() => {
        if (items.length > 0 && pincode.match(/^\d{6}$/)) {
            updateDeliveryCharges();  // Recalculate delivery charges when items or pincode change
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, pincode]);

    if (!user) {
        return <Navigate to="/login" />;
    }

    const handleQuantityChange = (cartItemId, quantityChange) => {
        const newQuantity = Math.max(1, quantityChange);  // Ensure quantity can't go below 1

        // Update the cart item quantity
        dispatch(updateCartAPI({ cartItemId, newQuantity }))
            .unwrap()
            .then(() => {
                // Recalculate the delivery charge after updating the quantity
                setSnackbarMessage('Item quantity updated!');
                updateDeliveryCharges();  // Trigger delivery charge recalculation
            })
            .catch(() => {
                setSnackbarMessage('Failed to update item quantity.');
            });

        setSnackbarVisible(true);
    };



    const handleDelete = (cartItemId) => {
        dispatch(removeFromCartAPI(cartItemId))
            .unwrap()
            .then(() => setSnackbarMessage('Item removed from cart.'))
            .catch(() => setSnackbarMessage('Failed to remove item from cart.'));
        setSnackbarVisible(true);
    };

    const updateDeliveryCharges = async () => {
        try {
            // Check if the cart is empty
            if (!items.length) {
                setErr('Your cart is empty.');
                return;
            }

            // Check delivery availability for all items at the given pincode
            const results = await Promise.all(
                items.map((item) => DeliveryService.checkPin(item.productId, pincode))
            );

            // Check if all items are deliverable to the given PIN
            const allDeliverable = results.every((res) => res.data?.data?.deliveryAvailable);

            if (allDeliverable) {
                // Recalculate the delivery charges for each item
                const deliveryResult = await Promise.all(
                    items.map(async (i) => {
                        const weight = i.size * i.quantity;
                        const deliveryData = {
                            productId: i.productId,
                            pincode: parseInt(pincode),
                            weight: parseInt(weight),
                            unit: i.unit,
                            amount: parseInt(totalPrice.totalAmount)
                        };

                        try {
                            // Fetch the delivery charges for each item
                            const response = await DeliveryService.deliveryCharge(deliveryData);

                            if (response.data?.status === 200 && !response.data?.error) {
                                return response.data.data?.deliveryCharges;
                            } else {
                                throw new Error(response.data?.message || "Error in calculating delivery charge.");
                            }
                        } catch (error) {
                            throw new Error(`Failed to fetch delivery charge for product ${i.productId}: ${error.message}`);
                        }
                    })
                );

                // Check if valid delivery charges were returned and update the delivery charge state
                if (deliveryResult && deliveryResult.length > 0) {
                    const sum = deliveryResult.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    setDelivery(sum);
                } else {
                    setErr("No valid delivery charges were returned.");
                    console.error("No delivery charges data available.");
                }

                setSuccess("Delivery is available.");
                setErr("");
            } else {
                setErr("Delivery is not available for this product in the given PIN code.");
            }
        } catch (err) {
            setErr("An error occurred while checking delivery. Please try again later.");
            console.error("Error during PIN check:", err);
        }
    };

    const handlePlaceOrder = async () => {
        if (!items.length) {
            setSnackbarMessage('Your cart is empty.');
            setSnackbarVisible(true);
            return;
        }

        if (!pincode.match(/^\d{6}$/)) {
            setErr("Please enter a valid 6-digit PIN code.");
            return;
        }

        const results = await Promise.all(
            items.map((item) => DeliveryService.checkPin(item.productId, pincode))
        );
        const allDeliverable = results.every((res) => res.data.data.deliveryAvailable);

        if (allDeliverable) {
            navigate('/user/order', { state: { pincode, delivery } });
        } else {
            setErr("Delivery is not available for this product in the given PIN code.");
        }
    };

    const subTotal = totalPrice.totalPriceBeforeTax;
    const totalTax = totalPrice.totalTax;
    const totalAmount = totalPrice.totalAmount + delivery;

    return (
        <div className="page-wrapper ms-0 bg-gray-50">
            <div className="content px-6 py-8">
                <div className={items.length === 0 ? "grid grid-cols-1 lg:grid-cols-1 gap-8" : "grid grid-cols-1 lg:grid-cols-3 gap-8"}>
                    {/* Cart Items Section */}
                    <div className="lg:col-span-2 bg-white shadow-md rounded-lg">
                        <div className="px-6 py-6">
                            <h4 className="text-2xl font-semibold text-gray-800 mb-6">Your Shopping Cart</h4>

                            {/* Empty Cart State */}
                            {items.length === 0 ? (
                                <div className="text-center p-6 text-gray-500">
                                    <p>Your cart is empty.</p>
                                </div>
                            ) : (
                                <>
                                    {/* Cart Item List */}
                                    <div className="totalitem mb-6">
                                        <h4 className="text-lg font-medium text-gray-700">Total items: {items.length}</h4>
                                    </div>
                                    {items.map((item, index) => (
                                        <div key={index} className="flex flex-col sm:flex-row items-center justify-between border-b py-4">
                                            <img
                                                crossOrigin="anonymous"
                                                src={`${process.env.REACT_APP_API_URL}/${item.productSlug}?t=${new Date().getTime()}`}
                                                alt={item.name}
                                                className="w-20 h-20 object-cover rounded-md sm:w-24 sm:h-24"
                                            />
                                            <div className="flex-1 pl-5 mt-4 sm:mt-0">
                                                <p className="font-medium text-lg text-gray-800">{item.name}</p>
                                                <p className="text-sm text-gray-500">Rate: ₹{item.price}</p>
                                                <p className="text-sm text-gray-500">
                                                    Packet Size:
                                                    {item.size && item.unit ? (
                                                        <span className="ml-1 text-red-500 font-bold">
                                                            {item.size} {item.unit}
                                                        </span>
                                                    ) : (
                                                        <span className="text-gray-400">Unavailable</span>
                                                    )}
                                                </p>
                                                <p className="text-gray-500 text-sm">Tax ({(item.taxpar * 100).toFixed(1)}%): ₹{(item.taxAmount).toFixed(2)}</p>
                                                <p className="text-gray-500 text-sm">Sub Total: ₹{(item.subTotal).toFixed(2)}</p>
                                            </div>
                                            <div className="flex items-center gap-4 mt-4 sm:mt-0">
                                                <div className="flex items-center space-x-2 border border-gray-300 rounded-md overflow-hidden">
                                                    {/* Decrease Button */}
                                                    <Tooltip title="Decrease Quantity" arrow>
                                                        <button
                                                            onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                                                            disabled={item.quantity <= 1}
                                                            className={`text-sm bg-gray-200 px-4 py-2 ${item.quantity <= 1 ? 'bg-gray-400' : ''}`}
                                                        >
                                                            <MinusIcon className="h-6 w-6 text-orange-600" />
                                                            {/* <img src="../../../assets/img/icons/minus.svg" alt="Decrease" className="w-4 h-4" /> */}
                                                        </button>
                                                    </Tooltip>
                                                    <div className="flex items-center justify-center w-10 h-10">
                                                        {status === 'loading' ? (
                                                            <div className="border-t-4 border-blue-500 border-solid w-8 h-8 rounded-full animate-spin"></div> // Spinner
                                                        ) : (
                                                            <span className="text-sm px-4">{item.quantity}</span> // Quantity
                                                        )}
                                                    </div>

                                                    {/* Increase Button */}
                                                    <Tooltip title="Increase Quantity" arrow>
                                                        <button
                                                            onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                                                            className="text-sm bg-gray-200 px-4 py-2"
                                                        >
                                                            <PlusIcon className="h-6 w-6 text-green-500" />
                                                            {/* <img src="../../../assets/img/icons/plus.svg" alt="Increase" className="w-5 h-5" /> */}
                                                        </button>
                                                    </Tooltip>
                                                </div>

                                                {/* Remove Button */}
                                                <button
                                                    onClick={() => handleDelete(item.id)}
                                                    className="uppercase text-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold transition duration-300 ease-in-out transform hover:scale-105"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Pincode Section */}
                                    <div className="col-lg-6 col-sm-6 col-12 p-2">
                                        <div className="form-group">
                                            <div className="flex items-center space-x-2">
                                                <MapPinIcon className="h-8 w-8 text-gray-500" />
                                                <input
                                                    type="text"
                                                    value={pincode}
                                                    onChange={(e) => setPinCode(e.target.value)}
                                                    className="w-full p-2 border rounded-md"
                                                    placeholder="Enter Delivery Pincode"
                                                />
                                                <button
                                                    onClick={updateDeliveryCharges}
                                                    className="px-4 py-2 text-gray-500 underline focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                                                >
                                                    Check
                                                </button>
                                            </div>
                                            {err || success ? (
                                                <p className={err ? "text-red-500 text-sm mt-2" : "text-green-600 text-sm mt-2"}>
                                                    {err || success}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Cart Summary Section (only shown if there are items) */}
                    {items.length !== 0 && (
                        <aside className="lg:col-span-1 bg-white shadow-md rounded-lg px-6 py-8">
                            <h4 className="text-xl font-semibold text-gray-800 mb-6">Cart Summary</h4>
                            <div className="space-y-4">
                                <div className="flex justify-between text-gray-700">
                                    <span>Total Price:</span>
                                    <span>₹{subTotal.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between text-gray-700">
                                    <span>TAX:</span>
                                    <span>₹{totalTax.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between text-gray-700">
                                    <span>Delivery Charges:</span>
                                    <span>₹{delivery}</span>
                                </div>
                                <hr className="my-4 border-t border-gray-300" />
                                <div className="flex justify-between text-lg font-semibold text-gray-800">
                                    <span>Total:</span>
                                    <span>₹{totalAmount}</span>
                                </div>
                            </div>

                            <div className="mt-6 justify-center">
                                <button
                                    onClick={handlePlaceOrder}
                                    className="w-full uppercase py-3 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                                >
                                    Place Order
                                </button>
                                <Link
                                    to="/"
                                    className="w-full mt-4 py-3 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 block text-center"
                                >
                                    Back to Shop
                                </Link>
                            </div>
                        </aside>
                    )}
                </div>
            </div>

            {/* Snackbar Notification */}
            {snackbarVisible && (
                <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
            )}
        </div>
    );
};

export default CartPage;
