const Sidebar = ({
  categories,
  onCategoryChange,
  onPriceChange,
  onDeliveryChange,
  onSellerChange,
  sellers
}) => {
  return (
    <div className="lg:w-full md:w-1/3 sm:w-1/2 p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-4 text-gray-700">Filters</h3>

      {/* Category Filter */}
      <div className="mb-6">
        <h4 className="font-medium text-gray-700 mb-2">Category</h4>
        <select
          onChange={(e) => onCategoryChange(e.target.value)}
          className="w-full p-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.categoriesName}
            </option>
          ))}
        </select>
      </div>

      {/* Price Filter */}
      <div className="mb-6">
        <h4 className="font-medium text-gray-700 mb-2">Price</h4>
        <select
          onChange={(e) => onPriceChange(e.target.value)}
          className="w-full p-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Prices</option>
          <option value="20">Under $20</option>
          <option value="50">Under $50</option>
          <option value="100">Under $100</option>
        </select>
      </div>

      {/* Delivery Time Filter */}
      <div className="mb-6">
        <h4 className="font-medium text-gray-700 mb-2">Delivery Time</h4>
        <label className="inline-flex items-center space-x-2">
          <input
            type="checkbox"
            onChange={(e) => onDeliveryChange(e.target.checked)}
            className="form-checkbox text-blue-500"
          />
          <span>1-3 Days Delivery</span>
        </label>
      </div>

      {/* Seller Filter */}
      <div className="mb-6">
        <h4 className="font-medium text-gray-700 mb-2">Seller</h4>
        {sellers.map((seller) => (
          <label key={seller.id} className="inline-flex items-center space-x-2">
            <input
              type="checkbox"
              value={seller.id}
              onChange={(e) => onSellerChange(e.target.checked, seller.id)}
              className="form-checkbox text-blue-500"
            />
            <span>{seller.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
