/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UnitService from "../../../services/admin/unit.services"
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";

const initialValues = {
  id: 0,
  unitType: '',
};
const UnitForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    unitType: Yup.string().required('Unit is required'),
  });


  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      unitType: values.unitType,
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await UnitService.update(id, data);
        console.log(response, "####")
        if (response.data) {
          navigate("/admin/unit");
          toast.success("unit updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await UnitService.create(data);
        if (response.data) {
          navigate("/admin/unit");
          toast.success("unit added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting unit:", error);
      toast.error(id ? "Failed to update unit." : "Failed to create unit.");
    } finally {
      setLoading(false);
    }
  };


  const fetchUnitData = useCallback(async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await UnitService.findOne(id);
        console.log(response)
        if (response.status) {
          const data = response.data.data;
          setData({
            // id: data.id,
            unitType: data.unitType,
          });
        } else {
          toast.error("Failed to load unit data.");
        }
      }

    } catch (error) {
      console.error("Error fetching unit data:", error);
      toast.error("Failed to load unit data.");
    }
  });

  useEffect(() => {
    fetchUnitData();
  }, []);
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Unit" : "Add Unit"}</h4>
          <h6>{(id > 0) ? "Update the unit details" : "Create a new unit"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="unitType">Unit Type <span className="manitory">*</span></label>
                      <Field
                        as="select"
                        name="unitType"
                        className="form-control"
                        id="unitType"
                      >
                        <option value="">Choose Status</option>
                        <option value="KG">KG</option>
                        <option value="QUINTAL">QUINTAL</option>
                        <option value="BOX">BOX</option>
                        <option value="LITTER">LITTER</option>
                        <option value="PACKET">PACKET</option>
                        <option value="PIECE">PIECE</option>
                      </Field>
                      <ErrorMessage name="unitType" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Unit" : "Add Unit"}
                    </button>
                    <Link to={"/admin/unit"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UnitForm;

