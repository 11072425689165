import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const OrderItemsTable = ({ orderItems }) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 1, borderRadius: 0, padding: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '16px' }}>
        Order Items Details
      </Typography>
      <Table aria-label="order items table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Price</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Quantity</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Sub Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderItems.map((item) => (
            <TableRow key={item.id} sx={{ '&:hover': { backgroundColor: '#f9f9f9' } }}>
              <TableCell>{item.productName}</TableCell>
              <TableCell>₹{item.price.toFixed(2)} /{item.unit}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>₹{item.subTotalPrice.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderItemsTable;
