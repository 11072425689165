import ApiInstance from "../../axios/api.auth";

const checkPin = async (productId, pinCode) => {
  return await ApiInstance.get(`user/check-delivery/${productId}/${pinCode}`);
};
const deliveryCharge = async (data) => {
  return await ApiInstance.post('user/delivery/charge', data);
};

const DeliveryService = {
  checkPin,
  deliveryCharge
};

export default DeliveryService;

