import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/public/Login';
import NoPageFound from './pages/public/NoPageFound';
import { ProtectedRoute } from './routes/ProtectedRoute';
import NoAccessPage from './pages/public/NoAccessPage';
import {
  AdminLayout,
  Dashboard,
  Profile,
  AboutUs,
  Merchant,
  MerchantForm,
  Categories,
  CategoriesForm,
  SubCategories,
  SubCategoriesForm,
  Brand,
  BrandForm,
  TaxRates,
  TaxRatesForm,
  Discount,
  DiscountForm,
  Unit,
  UnitForm,
  ExpanseCategories,
  Expanse,
  ExpanseForm,
  Coupon,
  CouponForm,
  Products,
  ProductsForm,
  ProductsDetails,
  Location,
  LocationForm,
  Banner,
  BannerForm,
  Order,
  User

} from './pages/admin';
import { SuccessPage, UserLayout, DashboardPage, ProfilePage, ProductDetailsPage, CartPage, OrderConfirmPage, AddressView, AddressForm, MyOrderPage, CheckoutPage, ShopPage } from './pages/user';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardRedirect from './routes/dashboardRedirect';
import Home from './pages/public/home';
import ErrorNotification from './pages/public/notification/ErrorNotification';
// import NoConnectionPage from './pages/public/NoConnectionPage';
import MerchantView from './pages/admin/merchants_/MerchantView';

const App = () => {
  // const [isOffline, setIsOffline] = useState(!navigator.onLine);

  // // Detect when the network status changes
  // useEffect(() => {
  //   const handleOnline = () => setIsOffline(false);
  //   const handleOffline = () => setIsOffline(true);

  //   // Listen for online and offline events
  //   window.addEventListener('online', handleOnline);
  //   window.addEventListener('offline', handleOffline);

  //   // Cleanup the event listeners when the component unmounts
  //   return () => {
  //     window.removeEventListener('online', handleOnline);
  //     window.removeEventListener('offline', handleOffline);
  //   };
  // }, []);

  // if (isOffline) {
  //   return <NoConnectionPage />;
  // }
  return (
    <Router future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ErrorNotification />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<DashboardRedirect />} />

        {/*++++++++++++++++Admin Panel+++++++++++++++*/}
        <Route
          path="/admin/"
          element={<ProtectedRoute element={<AdminLayout />} role="ADMIN" />}
        >
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="about" element={<AboutUs />} />
          <Route
            path="merchants"
            element={
              <Merchant />
            } />
          <Route
            path="merchants/:id?"
            element={
              <MerchantForm />
            }
          />
          <Route
            path="merchants/edit/:id?"
            element={
              <MerchantForm />
            }
          />
          <Route
            path="merchants/view/:id?"
            element={
              <MerchantView />
            }
          />
          <Route
            path="categories"
            element={
              <Categories />
            }
          />
          <Route
            path="categories/:id?"
            element={
              <CategoriesForm />
            }
          />
          <Route
            path="categories/edit/:id"
            element={
              <CategoriesForm />
            }
          />
          <Route
            path="sub-categories"
            element={
              <SubCategories />
            }
          />
          <Route
            path="sub-categories/:id?"
            element={
              <SubCategoriesForm />
            }
          />
          <Route
            path="sub-categories/edit/:id?"
            element={
              <SubCategoriesForm />
            }
          />
          <Route
            path="products"
            element={
              <Products />
            }
          />
          <Route
            path="products/detail/:id?"
            element={
              <ProductsDetails />
            }
          />
          <Route
            path="products/add"
            element={
              <ProductsForm />
            }
          />
          <Route
            path="products/edit/:id?"
            element={
              <ProductsForm />
            }
          />
          <Route
            path="brand"
            element={
              <Brand />
            }
          />
          <Route
            path="brand/:id?"
            element={
              <BrandForm />
            }
          />
          <Route
            path="brand/edit/:id?"
            element={
              <BrandForm />
            }
          />
          <Route
            path="tax"
            element={
              <TaxRates />
            }
          />
          <Route
            path="tax/:id?"
            element={
              <TaxRatesForm />
            }
          />
          <Route
            path="tax/edit/:id?"
            element={
              <TaxRatesForm />
            }
          />
          <Route
            path="discount"
            element={
              <Discount />
            }
          />
          <Route
            path="discount/:id?"
            element={
              <DiscountForm />
            }
          />
          <Route
            path="discount/edit/:id?"
            element={
              <DiscountForm />
            }
          />
          <Route
            path="unit"
            element={
              <Unit />
            }
          />
          <Route
            path="unit/:id?"
            element={
              <UnitForm />
            }
          />
          <Route
            path="unit/edit/:id?"
            element={
              <UnitForm />
            }
          />

          <Route
            path="expanse-categories"
            element={
              <ExpanseCategories />
            }
          />
          <Route
            path="expanse"
            element={
              <Expanse />
            }
          />
          <Route
            path="expanse/add"
            element={
              <ExpanseForm />
            }
          />
          <Route
            path="coupon"
            element={
              <Coupon />
            }
          />
          <Route
            path="coupon/:id?"
            element={
              <CouponForm />
            }
          />
          <Route
            path="coupon/edit/:id?"
            element={
              <CouponForm />
            }
          />

          <Route
            path="banner"
            element={
              <Banner />  // Changed to Banner component
            }
          />
          <Route
            path="banner/:id?"
            element={
              <BannerForm />  // Changed to BannerForm component
            }
          />
          <Route
            path="banner/edit/:id?"
            element={
              <BannerForm />  // Changed to BannerForm component
            }
          />

          <Route
            path="location"
            element={
              <Location />
            }
          />
          <Route
            path="location/:id?"
            element={
              <LocationForm />
            }
          />

          <Route
            path="location/edit/:id?"
            element={
              <LocationForm />
            }
          />

          <Route
            path="order"
            element={
              <Order />
            }
          />
          <Route
            path="users"
            element={
              <User />
            }
          />
        </Route>
        {/*++++++++++++++++User Panel+++++++++++++++*/}
        <Route
          path="/user/"
          element={<ProtectedRoute element={<UserLayout />} role="USER" />}>
          <Route index element={<DashboardPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="product-details/:productId" element={<ProductDetailsPage />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="order" element={<OrderConfirmPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="payment-result" element={<SuccessPage />} />
          <Route path="my-order" element={<MyOrderPage />} />
          <Route path="address" element={<AddressView />} />
          <Route path="address/:id?" element={<AddressForm />} />
          <Route path="address/edit/:id?" element={<AddressForm />} />
          <Route path="shop" element={<ShopPage />} />
        </Route>
        <Route path="/no-access" element={<NoAccessPage />} />
        <Route path="*" element={<NoPageFound />} />
      </Routes>
    </Router>
  );
};

export default App;
