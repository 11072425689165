import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Get payment status and order ID from URL query parameters or state
    const paymentStatus = new URLSearchParams(location.search).get('status') || location.state?.status;
    const paymentId = new URLSearchParams(location.search).get('payment_id');
    const orderId = new URLSearchParams(location.search).get('order_id') || location.state?.id;


    // Navigation handlers
    const handleContactSupport = () => navigate("/support"); // Redirect to support page
    const handleContinueShopping = () => navigate("/"); // Redirect to homepage

    // Conditional rendering based on payment status
    const renderContent = () => {
        if (!paymentStatus || !orderId) {
            return (
                <div className="flex justify-center items-center py-10">
                    <h2 className="text-3xl font-semibold text-gray-600">No order data available.</h2>
                </div>
            );
        }
        switch (paymentStatus) {
            case "success":
                return (
                    <>
                        <h2 className="text-3xl font-semibold text-green-600">Order Placed Successfully!</h2>
                        <p className="mt-4 text-lg text-gray-700">Your payment was successful. You will receive an email confirmation soon.</p>
                        <p className="mt-2 text-gray-600 uppercase">
                            Order ID: <span className="font-medium uppercase text-orange-400">{orderId}</span>
                        </p>
                        <p className="mt-2 text-gray-600 uppercase">
                            Payment ID: <span className="font-medium uppercase text-orange-400">{paymentId}</span>
                        </p>
                        <div className="mt-6">
                            <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300" onClick={handleContinueShopping}>
                                Continue Shopping
                            </button>
                        </div>
                    </>
                );
            case "failure":
                return (
                    <>
                        <h2 className="text-3xl font-semibold text-red-600">Payment Failed</h2>
                        <p className="mt-4 text-lg text-gray-700">Unfortunately, there was an issue processing your payment. Please try again or contact support.</p>
                        <div className="mt-6 space-x-4">
                            <button className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition duration-300" onClick={handleContinueShopping}>
                                Continue Shopping
                            </button>
                            <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300" onClick={handleContactSupport}>
                                Contact Support
                            </button>
                        </div>
                    </>
                );
            case "cod":
                return (
                    <>
                        <h2 className="text-3xl font-semibold text-green-600">Order Placed Successfully!</h2>
                        <p className="mt-4 text-lg text-gray-700">Your order has been successfully placed. You will receive an email confirmation soon.</p>
                        <p className="mt-2 text-gray-600 uppercase">Order ID: <span className="font-medium uppercase text-orange-400">{orderId}</span></p>
                        <div className="mt-6">
                            <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300" onClick={handleContinueShopping}>
                                Continue Shopping
                            </button>
                        </div>
                    </>
                );
            default:
                return <h2 className="text-3xl font-semibold text-gray-600">Invalid Status</h2>;
        }
    };

    return (
        <div className="page-wrapper ms-0">
            <div className="flex flex-col items-center justify-center min-h-screen bg-green-50">
                <div className="text-center p-6 bg-white rounded-lg shadow-md max-w-lg w-full">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
