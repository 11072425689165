import React, { useState, useEffect, useCallback } from "react";
import DataTable from "../../../components/Datatable/DataTable";
import UserService from "../../../services/admin/user.services"; 
import { Link } from "react-router-dom";


const User = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    }, 
    { field: 'role', headerName: 'Role', width: 100 },  
    { field: 'email', headerName: 'Email', width: 150 },  
    { field: 'lastLogin', headerName: 'Last Login', width: 150 },  
    { field: 'mobile', headerName: 'Mobile', width: 100 },  
    { field: 'openId', headerName: 'Open Id', width: 100 },  
    { field: 'status', headerName: 'Status', width: 100 ,
        renderCell: (params) => {
          const status = params.value ? 'Active' : 'Inactive';
          const badgeClass = params.value ? 'badge' : 'badge'; 
  
          const badgeStyle = {
            padding: '5px 10px',
            fontSize: '12px',
            backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
            color: params.value ? '#006400' : '#8B0000', 
          };
  
          return (
            <span className={badgeClass} style={badgeStyle}>
              {status}
            </span>
          );
        }
      },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await UserService.findAll(page, pageSize, searchTerm);  
      console.log(response.data.data.rows,"+++++++++++++")
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);






  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>User List</h4>  
            <h6>Manage your User</h6>  
          </div>
          {/* <div className="page-btn">
            <Link to={"add"} className="btn btn-added">
              <img src="../../../assets/img/icons/plus.svg" className="me-2" alt="img" />Add Banner 
            </Link>
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset">
                    <img src="../../../assets/img/icons/search-white.svg" alt="img" />
                  </Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="form-control form-control-sm"
                        placeholder="Search..."
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
