import ApiInstance from "../../axios/api.auth";

const getAll = async() => {
  return await ApiInstance.get("user/cart/items/");
};

const create = async(data) => {
  return await ApiInstance.post("user/cart/add/", data);
};
const destroy = async(cartItemId) => {
  return await ApiInstance.delete(`user/cart/remove/` + cartItemId);
};

const update = async(data) => {
  return await ApiInstance.put("user/cart/update/", data);
};

const CartService = {
  getAll,
  create,
  update,
  destroy
};

export default CartService;

