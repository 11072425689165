import React, { useState, useEffect, useCallback } from "react";
import DataTable from "../../../components/Datatable/DataTable";
import BannerService from "../../../services/admin/banner.services"; 
import { Link } from "react-router-dom";
import DeletePopup from "../../../components/popups/DeletePopup";
import { toast } from "react-toastify";
import { PencilIcon, TrashIcon, PowerIcon } from "@heroicons/react/20/solid";

const Banner = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    {
      field: 'attachmentName',
      headerName: 'Banner Logo',  
      width: 120,
      renderCell: (params) => {
        const imageUrl = `${BASE_URL}${params.value}?t=${new Date().getTime()}`; 
        return (
          <img
            className="product-img"
            crossOrigin="anonymous"
            src={imageUrl}
            alt="Banner Logo"
            style={{ width: '50px', height: '50px' }}
          />
        );
      },
    },
    { field: 'title', headerName: 'Title', width: 200 },  
    { field: 'description', headerName: 'Description', width: 200 },  
    { field: 'isActive', headerName: 'Status', width: 100 ,
        renderCell: (params) => {
          const status = params.value ? 'Active' : 'Inactive';
          const badgeClass = params.value ? 'badge' : 'badge'; 
  
          const badgeStyle = {
            padding: '5px 10px',
            fontSize: '12px',
            backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
            color: params.value ? '#006400' : '#8B0000', 
          };
  
          return (
            <span className={badgeClass} style={badgeStyle}>
              {status}
            </span>
          );
        }// Conv
      },
    {
      field: 'action', headerName: 'Action', width: 200, renderCell: (params) => {
        const isDeactivated = !params.row.isActive;
        return(
        <div className="flex mt-3">
          <Link className="me-3" to={`edit/${params.row.id}`}>
            <PencilIcon className="h-5 w-5 text-green-600" />
          </Link>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
            <TrashIcon className="h-5 w-5 text-red-600" />
          </Link>
          <button
              className="me-3"
              onClick={() => handleDeactivate(params.row.id)}
              disabled={isDeactivated || loading} 
            >
              <PowerIcon className="h-5 w-5 text-orange-500" />
            </button>
        </div>
      )},
    },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await BannerService.findAll(page, pageSize, searchTerm);  
      console.log(response.data.data.rows,"+++++++++++++")
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await BannerService.destroy(deleteId);  
      handleCloseDialog();
      toast.success("The banner has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting banner: ' + error);
      console.error('Error deleting banner:', error);
    }
  };

  const handleDeactivate = async (id) => {
    setLoading(true); 
    try {
      await BannerService.deactivate(id);
      toast.success("Banner deactivated successfully!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deactivating banner: ' + error);
      console.error('Error deactivating banner:', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Banner List</h4>  
            <h6>Manage your Banners</h6>  
          </div>
          <div className="page-btn">
            <Link to={"add"} className="btn btn-added">
              <img src="../../../assets/img/icons/plus.svg" className="me-2" alt="img" />Add Banner 
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset">
                    <img src="../../../assets/img/icons/search-white.svg" alt="img" />
                  </Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="form-control form-control-sm"
                        placeholder="Search..."
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
            <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
