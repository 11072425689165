import ApiInstance from "../../axios/api.auth";

const findAll = async(search) => {
  return await ApiInstance.get(`admin/orders?search=${search}`);
};

const update = async(data) => {
  return await ApiInstance.put(`admin/orders/update`,data);
};


const OrderService = {
  findAll,
  update
};

export default OrderService;
