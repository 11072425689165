/* eslint-disable jsx-a11y/img-redundant-alt */

import React from 'react';
import { Link } from 'react-router-dom';
import { StarIcon } from "@heroicons/react/20/solid";

const ProductCard = ({ data }) => {
  const percentage = data.quantity_sizes[0].discount_master.percentage;
  const originalPrice = data.quantity_sizes[0].price;
  // Calculate the discounted price
  const BASE_URL = process.env.REACT_APP_API_URL;

  const discountPercentage = originalPrice - (originalPrice * (percentage / 100));

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
      <div className="product-card flex-fill border rounded-lg shadow-md overflow-hidden">

        <Link to={`/user/product-details/${data.id}`}>

          <div className="product-card-img relative">
            <img
              crossOrigin="anonymous"
              src={`${BASE_URL}${data.productImages[0].attachmentName}`}
              alt={`Image of ${data.productName}`}
              className="h-52 w-full object-cover transition-all duration-300 transform hover:scale-105 hover:shadow-lg swiper-image"
            />
            <h6
              className={`absolute top-2 left-2 text-white text-xs px-2 py-1 rounded-full animate-bounce ${data.quantity_sizes[0].stockQuantity !== 0 ? 'bg-green-500' : 'bg-orange-500'
                }`}
            >
              {data.quantity_sizes[0].stockQuantity !== 0 ? (
                <p>In Stock</p>
              ) : (
                <p>Out of Stock</p>
              )}
            </h6>


            <div className={`check-product absolute top-0 right-0 p-2 rounded-full ${data.vegetarianType === "vegetarian" ? 'bg-green-500' : 'bg-red-500'}`}>
              <i className="fa fa-check text-white" />
            </div>
          </div>
        </Link>

        <div className="product-card-content p-4">
          <h5 className="category text-sm text-gray-500">{data.categories_master.categoriesName}</h5>
          <Link to={`/user/product-details/${data.id}`} className="text-lg font-semibold hover:underline mt-1">
            {data.productName}
          </Link>
          <h5 className="category text-sm text-gray-500">{data.quantity_sizes[0].size}{data.quantity_sizes[0].unit_master.unitType}</h5>
          <div className="mt-2 flex items-center justify-start space-x-2">
            <div className="flex items-center space-x-1">
              <span className="flex items-center justify-center bg-green-500 text-white badge ">
                {4.2}
                <StarIcon className="h-3 w-3 ml-1 text-white" />
              </span>
              <span className="text-sm text-gray-700">{22} Ratings</span>
              <span className="text-sm text-gray-700">& {33} Reviews</span>
            </div>
          </div>
          <div className="mt-1 flex items-center justify-start space-x-1">
            <h6 className="price text-xl font-bold text-black mt-1 space-x-1">
              <span> ₹{discountPercentage.toFixed(2)}</span>
              <span className="line-through font-sans text-sm text-gray-400">₹{data.quantity_sizes[0].price.toFixed(2)}</span>
              <span className="discount font-semibold text-sm text-green-500">{Math.round(percentage)}% off</span>
            </h6>
          </div>

          {/* 
          <div className="bank-offer text-sm text-blue-500 mt-2">
            <i className="fa fa-credit-card mr-1" /> Bank Offer: SBI
          </div> */}

          {/* <button
            className="w-full uppercase py-2 mt-4 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => onAddToCart(data)}
          >
            Add to Cart
          </button> */}

        </div>
      </div>
    </div>
  );
};

export default ProductCard;
