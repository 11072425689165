import ApiInstance from "../../axios/api.auth";


const getProfile = () => {
  return ApiInstance.get("user/profile");
};

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`/admin/users?page=${page}&size=${size}&search=${search}`);
};


const UserService = {
  getProfile,
  findAll
};

export default UserService;
