import ApiInstance from "../../axios/api.auth";


const getAllProduct = async () => {
  return await ApiInstance.get(`public/products`);
};
const getAllProductFilter = async (filter) => {
  const categoryParam = encodeURIComponent(filter.categoryId || '');
  const searchParam = encodeURIComponent(filter.search || '');
  return await ApiInstance.get(`public/products?search=${searchParam}&categories=${categoryParam}`);
};

const findOneByProductId = async (productId) => {
  return await ApiInstance.get(`public/products/${productId}`);
};
const findAllCategories = async () => {
  return await ApiInstance.get("public/categories");
};
const findAllMerchantByProductId = async (id) => {
  return await ApiInstance.get(`user/products/${id}/merchants`);
};

const ProductsService = {
  findOneByProductId,
  findAllCategories,
  findAllMerchantByProductId,
  getAllProduct,
  getAllProductFilter
};

export default ProductsService;
