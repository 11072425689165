
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import OrderService from "../../../services/admin/order.services";
import DataTable from "../../../components/Datatable/DataTable";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import OrderItemsTable from "../../../components/Datatable/OrderItemsTable";

const Order = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [open, setOpen] = useState(false); // Modal for Order Items
  const [selectedOrderItems, setSelectedOrderItems] = useState([]); // State for selected order items
  const [selectedOrderId, setSelectedOrderId] = useState(null); // State for selected order ID
  const [openPopup, setOpenPopup] = useState(false); // Modal for updating order status
  const [selectedItem, setSelectedItem] = useState(""); // State for selected dropdown item

  // Handle dropdown change
  const handleDropdownChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await OrderService.findAll(searchTerm);
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleOpenOrderItems = (orderItems) => {
    setSelectedOrderItems(orderItems);
    setOpen(true); // Open modal when clicking "Order Items"
  };

  const handleClose = () => {
    setOpen(false); // Close modal
  };

  const handleOpenPopup = (orderId) => {
    // console.log(orderId, "Selected order ID for status update");
    setSelectedOrderId(orderId); // Set selected order ID
    setOpenPopup(true); // Open popup for updating order status
  };

  const handleSave = async () => {
    console.log(selectedOrderId, "selectedOrderId")

    if (!selectedItem) {
      alert("Please select an item status.");
      return;
    }

    if (!selectedOrderId) {
      alert("No order selected.");
      return;
    }

    setLoading(true); // Start loading state
    try {
      const data = {
        orderId: selectedOrderId, // The order ID
        status: selectedItem.toUpperCase(), // Ensure status is in uppercase
      };
      await OrderService.update(data);
      console.log("Order status updated successfully");
      // Update the local table data
      setData((prevData) =>
        prevData.map((order) =>
          order.orderNumber === selectedOrderId
            ? { ...order, status: selectedItem.toUpperCase() }
            : order
        )
      );
      // Close the popup after successful save
      setOpenPopup(false); // Close the popup
      toast.success(`Order status ${selectedItem} successfully.`);

    } catch (error) {
      console.error("Error updating order status:", error);
    } finally {
      setLoading(false); // End loading state
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>;
      }
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 180,
      renderCell: (params) => <span>{params.row.address_master.fullName}</span>
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 180,
      renderCell: (params) => <span>{params.row.address_master.phone}</span>
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      width: 150,
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 150,
      renderCell: (params) => <span>{format(new Date(params.value), 'MM-dd-yyyy')}</span>
    },
    {
      field: 'order_items',
      headerName: 'Order Items',
      width: 150,
      renderCell: (params) => (
        <span
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => handleOpenOrderItems(params.row.order_items)}
        >
          {params.row.order_items.length} items
        </span>
      )
    },
    {
      field: 'couponCode',
      headerName: 'Coupon Code',
      width: 150,
      renderCell: (params) => <span>{params.value}</span>
    },
    {
      field: "shippingAddress",
      headerName: "Shipping Address",
      renderCell: (params) => {
        const address = params.value;
        return (
          <div className="relative flex flex-col space-y-1">
            <span
              className="text-sm whitespace-pre-wrap cursor-pointer font-thin"
              title={address}
            >
              {address}
            </span>
          </div>
        );
      },
      width: 300,
    },
    {
      field: 'shippingMethod',
      headerName: 'Shipping Method',
      width: 150,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },

    {
      field: 'razorpayPaymentId',
      headerName: 'Razorpay PaymentId',
      width: 200,
      renderCell: (params) => <span>{params.value}</span>
    },
    {
      field: 'tax',
      headerName: 'Tax',
      width: 120,
      renderCell: (params) => <span>₹{params.value}</span>
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      width: 120,
      renderCell: (params) => <span>₹{params.value}</span>
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 120,
    },
    {
      field: 'expectedDelivery',
      headerName: 'Expected Delivery',
      width: 180,
      renderCell: (params) => <span>{params.value}</span>
    },
    {
      field: 'status',
      headerName: 'Order Status',
      width: 200,
      renderCell: (params) => {
        const status = params.value;
        let badgeClass = '';
        let viewStatus = '';
        if (status === "PLACED") {
          badgeClass = "bg-blue-500 text-white";
          viewStatus = "PLACED";
        } else if (status === "SHIPPED") {
          badgeClass = "bg-yellow-500 text-white";
          viewStatus = "SHIPPED";
        } else if (status === "OFD") {
          badgeClass = "bg-green-500 text-white";
          viewStatus = "OUT FOR DELIVERY";
        } else if (status === "CANCELLED") {
          badgeClass = "bg-red-500 text-white";
          viewStatus = "CANCELLED";
        } else {
          badgeClass = "bg-gray-500 text-white"; // Default case
          viewStatus = "DELIVERED";
        }
        return <span className={badgeClass} style={{ padding: '5px 10px', fontSize: '12px' }}>{viewStatus}</span>;
      }
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => <span>{params.value}</span>
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 150,
      renderCell: (params) => <span>{params.value}</span>
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <button className="text-blue-600 cursor-pointer hover:underline"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenPopup(params.row.orderNumber)} // Pass orderNumber to open status update dialog
        >
          Change Status
        </button>
      )
    },
  ];

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Order List</h4>
          <h6>Manage your Orders</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <Link className="btn btn-searchset">
                  <img src="../../../assets/img/icons/search-white.svg" alt="search" />
                </Link>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control form-control-sm"
                      placeholder="Search..."
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={data}
              loading={loading}
              totalCount={totalCount}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
            />
          </div>
        </div>
      </div>

      {/* Modal for Order Items */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Order Items</DialogTitle>
        <DialogContent>
          <OrderItemsTable orderItems={selectedOrderItems} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for selecting order status */}
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        maxWidth="sm" // Adjusts the dialog to a medium size
        fullWidth // Makes the dialog width take up the full available width
      >
        <DialogTitle>Select Status</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="item-select-label">Select Status</InputLabel>
            <Select
              labelId="item-select-label"
              id="item-select"
              value={selectedItem}
              label="Select Status"
              onChange={handleDropdownChange}
              fullWidth // Ensures the select box takes the full width of the dialog content
            >
              <MenuItem value="placed">PLACED</MenuItem>
              <MenuItem value="shipped">SHIPPED</MenuItem>
              <MenuItem value="ofd">OUT FOR DELIVERY</MenuItem>
              <MenuItem value="delivered">DELIVERED</MenuItem>
              <MenuItem value="cancelled">CANCELLED</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={() => setOpenPopup(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Order;
