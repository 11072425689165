import ApiInstance from "../../axios/api.auth";

const findAll = async (page, size, search) => {
  return await ApiInstance.get(`admin/merchants?page=${page}&size=${size}&search=${search}`);
};
const findAllMerchant = async () => {
  return await ApiInstance.get(`admin/merchants`);
};
const findOne = async (id) => {
  return await ApiInstance.get("admin/merchants/" + id);
};
const create = async (data) => {
  return await ApiInstance.post("admin/merchants/", data);
};
const destroy = async (id) => {
  return await ApiInstance.delete("admin/merchants/" + id);
};
const update = async (id, data) => {
  return await ApiInstance.put("admin/merchants/" + id, data);
};
// const merchantProductLink = async (merchantId, data) => {
//   return await ApiInstance.post(`admin/merchants/${merchantId}/products`, data);
// };
// const merchantProductList = async (merchantId) => {
//   return await ApiInstance.get(`admin/merchants/${merchantId}/products`);
// };
// const merchantProductDelete = async (id, productId) => {
//   return await ApiInstance.delete(`admin/merchants/${id}/products/${productId}`);
// };

const MerchantService = {
  findAll,
  findAllMerchant,
  findOne,
  create,
  update,
  destroy,
  // merchantProductLink,
  // merchantProductList,
  // merchantProductDelete
};

export default MerchantService;
