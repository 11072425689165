import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AddressService from '../services/user/address.services';


export const fetchAllAddress = createAsyncThunk(
    'address/fetchAllAddress',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AddressService.getAllAddress();
            return response.data.data; // Return the list of addresses
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);

export const findByIdAddress = createAsyncThunk(
    'address/findByIdAddress',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AddressService.findByIdAddress(id);
            return response.data.data; // Return the fetched address
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);

export const deleteAddress = createAsyncThunk(
    'address/deleteAddress',
    async (id, { rejectWithValue }) => {
        try {
            await AddressService.destroy(id);
            return { id }; // Return the deleted address ID
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);

// Slice for managing address state
const addressSlice = createSlice({
    name: 'address',
    initialState: {
        address: {}, // Current address (created or viewed)
        addresses: [], // List of all addresses
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null, // Error message if any
    },
    extraReducers: (builder) => {
        builder
            // Fetch All Addresses
            .addCase(fetchAllAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.addresses = action.payload; // Store the list of addresses
            })
            .addCase(fetchAllAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Find Address by ID
            .addCase(findByIdAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(findByIdAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.address = action.payload; // Store the fetched address
            })
            .addCase(findByIdAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


            // Delete Address
            .addCase(deleteAddress.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteAddress.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.addresses = state.addresses.filter(
                    (address) => address.id !== action.payload.id
                ); // Remove the deleted address from the list
            })
            .addCase(deleteAddress.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default addressSlice.reducer;
