/* eslint-disable react/react-in-jsx-scope */
import { Link } from "react-router-dom";
import DataTable from "../../../components/Datatable/DataTable"
import React, { useState, useEffect, useCallback } from "react";
import MerchantService from '../../../services/admin/merchant.services';
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";


function Merchant() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    {
      field: 'attachmentName',
      headerName: 'Merchant Image',
      width: 120,
      renderCell: (params) => {
        const imageUrl = `${BASE_URL}${params.value}?t=${new Date().getTime()}`; 
        return (
          <img
            className="product-img"
            crossOrigin="anonymous"
            src={imageUrl}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />
        );
      },
    },
    { field: 'merchantType', headerName: 'Merchant Type', width: 200 },
    { field: 'branchCode', headerName: 'Branch Code', width: 200 },
    { field: 'branchType', headerName: 'Branch Type', width: 200 },
    { field: 'firstName', headerName: 'First Name', width: 200 },
    { field: 'lastName', headerName: 'Last Name', width: 200 },
    { field: 'emailId', headerName: 'Email Id', width: 200 },
    { field: 'shopName', headerName: 'Shop Name', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 200 },
    { field: 'state', headerName: 'State', width: 150 },
    { field: 'district', headerName: 'District', width: 150 },
    { field: 'pincode', headerName: 'Pincode', width: 150 },
    {
      field: "shopAddress",
      headerName: "Address",
      renderCell: (params) => {
        const address = params.value;
        return (
          <div className="relative flex flex-col space-y-1">
            <span
              className="text-sm whitespace-pre-wrap cursor-pointer font-thin"
              title={address}
            >
              {address}
            </span>
          </div>
        );
      },
      width: 300,
    },
    { field: 'gstNo', headerName: 'Gst No', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const status = params.value ? 'Active' : 'Inactive';
        const badgeClass = params.value ? 'badge' : 'badge'; 

        const badgeStyle = {
          padding: '5px 10px',
          fontSize: '12px',
          backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
          color: params.value ? '#006400' : '#8B0000', 
        };

        return (
          <span className={badgeClass} style={badgeStyle}>
            {status}
          </span>
        );
      }

    },
    {
      field: 'action', headerName: 'Action', width: 200, renderCell: (params) => (
        <div className="flex mt-3">
          <Tooltip title="View & Product Link" arrow>
           <Link className="me-3" to={`view/${params.row.id}`} >
          <EyeIcon className="h-5 w-5 text-sky-600"/>
          </Link> 
          </Tooltip>
          <Tooltip title="Edit" arrow>
          <Link className="me-3" to={`edit/${params.row.id}`} >
          <PencilIcon className="h-5 w-5 text-green-600"/>
          </Link>
          </Tooltip> 
          <Tooltip title="Delete" arrow>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
          <TrashIcon className="h-5 w-5 text-red-600"/>
          </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await MerchantService.findAll(page, pageSize, searchTerm);
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await MerchantService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The merchant has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting merchant: ' + error);
      console.error('Error deleting merchant:', error);
    }
  };


  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Merchant List</h4>
          <h6>Manage your Merchants</h6>
        </div>
        <div className="page-btn">
          <Link to="add" className="btn btn-added">
            <img src="../../../assets/img/icons/plus.svg" alt="img" />
            Add Merchant
          </Link>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                  <input type="search" value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
            </div>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={data}
              loading={loading}
              totalCount={totalCount}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
            />
          </div>
          <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
        </div>
      </div>
    </div>
  );
}
export default Merchant;
