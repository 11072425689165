import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import MerchantService from "../../../services/admin/merchant.services";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDistrict, fetchAllState } from "../../../features/location.slice";
import { Tabs, Tab, Box, Typography } from "@mui/material";

const initialValues = {
  id: 0,
  merchantType: '',
  branchType: '',
  firstName: '',
  lastName: '',
  emailId: '',
  shopName: '',
  mobile: '',
  state: '',
  district: '',
  pincode: '',
  shopAddress: '',
  gstNo: '',
  attachmentId: '',
  attachmentName: '',
  status: ''
};

const MerchantForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const { stateData, districtData, statusState } = useSelector((state) => state.location);
  const dispatch = useDispatch();
  const [data, setData] = useState(initialValues);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);

  const validationSchema = Yup.object({
    merchantType: Yup.string().required("Merchant type is required"),
    branchType: Yup.string().required("Branch type is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    emailId: Yup.string().email("Invalid email address").required("Email is required"),
    shopName: Yup.string().required("Shop name is required"),
    state: Yup.string().required("State name is required"),
    district: Yup.string().required("District name is required"),
    pincode: Yup.string().required("Pincode is required"),
    mobile: Yup.string().matches(/^\d{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    shopAddress: Yup.string().required("Shop address is required"),
    gstNo: Yup.string().matches(/^[0-9A-Z]{15}$/, "GST number must be 15 characters")
      .required("GST number is required"),
    attachmentName: Yup.string().required("A file is required"),
    status: Yup.string().required("Status is required"),
  });

  const handleStateChange = (e, setFieldValue) => {
    const selectedState = e.target.value;
    setFieldValue("state", selectedState);
    setFieldValue("district", "");
    if (selectedState) {
      dispatch(fetchAllDistrict({ stateName: selectedState }));
    }
  };
  const handleDistrictChange = (e, setFieldValue) => {
    const selectedDistrict = e.target.value;
    setFieldValue("district", selectedDistrict);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const reqData = {
      merchantType: values.merchantType,
      branchType: values.branchType,
      firstName: values.firstName,
      lastName: values.lastName,
      emailId: values.emailId,
      mobile: values.mobile,
      shopAddress: values.shopAddress,
      gstNo: values.gstNo,
      shopName: values.shopName,
      state: values.state,
      district: values.district,
      pincode: values.pincode,
      attachmentId: values.attachmentId,
      attachmentName: values.attachmentName,
      status: values.status
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await MerchantService.update(id, reqData);
        if (response.data) {
          navigate("/admin/merchants");
          toast.success("Merchant updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await MerchantService.create(reqData);
        if (response.data) {
          navigate("/admin/merchants");
          toast.success("Merchant added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error(id ? "Failed to update merchant." : "Failed to create merchant.");
    } finally {
      setLoading(false);
    }
  }

  const fetchMerchantData = useCallback(async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await MerchantService.findOne(id);
        const data = response.data.data;
        if (data) {
          setData({
            merchantType: data.merchantType,
            branchType: data.branchType,
            firstName: data.firstName,
            lastName: data.lastName,
            emailId: data.emailId,
            mobile: data.mobile,
            state: data.state,
            district: data.district,
            pincode: data.pincode,
            shopAddress: data.shopAddress,
            gstNo: data.gstNo,
            shopName: data.shopName,
            attachmentId: data.attachmentId,
            attachmentName: data.attachmentName,
            status: data.status
          });
        } else {
          toast.error("Failed to load merchant data.");
        }
      }
    } catch (error) {
      toast.error("Failed to load merchant data.");
    }
  }, [id]);

  useEffect(() => {
    fetchMerchantData();
  }, [fetchMerchantData]);

  useEffect(() => {
    if (statusState === "idle") {
      dispatch(fetchAllState());
    }
  }, [dispatch, statusState]);

  const previewStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    gap: "10px",
    transition: "border-color 0.2s ease",
    borderRadius: "9px",
  };

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Merchant" : "Add Merchant"}</h4>
          <h6>{(id > 0) ? "Update the merchant details" : "Create a new merchant"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}    // Ensure this is true
            validateOnChange={true}
          >
            {({ setFieldValue, values, isSubmitting }) => (
              <Form>

                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  aria-label="merchant form tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Personal Details" />
                  <Tab label="Shop Details" />
                  <Tab label="Address & Status" />
                  <Tab label="Shop Image" />
                </Tabs>


                <Box sx={{ padding: 3 }}>
                  {/* General Info Tab */}
                  <Typography component="div" sx={{ border: "1px solid #ccc", borderRadius: "8px", padding: 3 }} role="tabpanel" hidden={activeTab !== 0}>
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Merchant Type <span className="manitory">*</span></label>
                          <Field name="merchantType" as="select" className="form-control">
                            <option value="">Select Merchant Type</option>
                            <option value="DISTRIBUTOR">DISTRIBUTOR</option>
                            <option value="RETAILOR">RETAILOR</option>
                            <option value="KGP-CENTER">KGP-CENTER</option>
                          </Field>
                          <ErrorMessage name="merchantType" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Branch Type <span className="manitory">*</span></label>
                          <Field name="branchType" as="select" className="form-control">
                            <option value="">Select Branch Type</option>
                            <option value="MAIN-BRANCH">MAIN-BRANCH</option>
                            <option value="SUB-BRANCH">SUB-BRANCH</option>
                          </Field>
                          <ErrorMessage name="branchType" component="div" className="text-danger" />
                        </div>

                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>First Name <span className="manitory">*</span></label>
                          <Field type="text" name="firstName" className="form-control" />
                          <ErrorMessage name="firstName" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Last Name <span className="manitory">*</span></label>
                          <Field type="text" name="lastName" className="form-control" />
                          <ErrorMessage name="lastName" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email <span className="manitory">*</span></label>
                          <Field type="text" name="emailId" className="form-control" />
                          <ErrorMessage name="emailId" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Phone <span className="manitory">*</span></label>
                          <Field type="text" name="mobile" className="form-control" />
                          <ErrorMessage name="mobile" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </Typography>
                  <Typography component="div" sx={{ border: "1px solid #ccc", borderRadius: "8px", padding: 3 }} role="tabpanel" hidden={activeTab !== 1}>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Shop Name <span className="manitory">*</span></label>
                          <Field type="text" name="shopName" className="form-control" />
                          <ErrorMessage name="shopName" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>GST No <span className="manitory">*</span></label>
                          <Field type="text" name="gstNo" className="form-control" />
                          <ErrorMessage name="gstNo" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>State <span className="manitory">*</span></label>
                          <Field
                            as="select"
                            name="state"
                            className="form-control"
                            id="state"
                            value={values.state}
                            onChange={(e) => handleStateChange(e, setFieldValue)}
                          >
                            <option value="">Select State</option>
                            {stateData.map((item, index) => (
                               <option key={index} value={item.state_code}>{item.stateName}</option>
                            ))}
                          </Field>
                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <label>District <span className="manitory">*</span></label>
                          <Field
                            as="select"
                            name="district"
                            className="form-control"
                            id="district"
                            value={values.district}
                            onChange={(e) => handleDistrictChange(e, setFieldValue)}
                          >
                            <option value="">Select District</option>
                            {districtData.map((item, index) => (
                             <option key={index} value={item.districtCode}>{item.distName}</option>
                            ))}
                          </Field>
                          <ErrorMessage name="district" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </Typography>

                  <Typography component="div" sx={{ border: "1px solid #ccc", borderRadius: "8px", padding: 3 }} role="tabpanel" hidden={activeTab !== 2}>
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Shop Address <span className="manitory">*</span></label>
                          <Field type="text" name="shopAddress" className="form-control" />
                          <ErrorMessage name="shopAddress" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Pincode <span className="manitory">*</span></label>
                          <Field type="text" name="pincode" className="form-control" />
                          <ErrorMessage name="pincode" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Status <span className="manitory">*</span></label>
                          <Field as="select" name="status" className="form-control">
                            <option value="">Choose Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </Field>
                          <ErrorMessage name="status" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </Typography>
                  <Typography component="div" sx={{ border: "1px solid #ccc", borderRadius: "8px", padding: 3 }} role="tabpanel" hidden={activeTab !== 3}>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Shop Image: <span className="manitory">*</span> </label>
                        <FileUpload setUploadRequest={[{
                          attachmentId: data.attachmentId,
                          attachmentName: data.attachmentName
                        }]}
                          setUploadResponse={(response) => {
                            setFieldValue('attachmentId', response.attachmentId);
                            setFieldValue('attachmentName', response.attachmentName);
                          }}
                        />
                        <ErrorMessage name="attachmentName" component="div" style={{ color: 'red' }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      {(!isEmpty(id) && data.attachmentName) ? (
                        <div className="form-group">
                          <label>Old Image:</label>
                          <div style={previewStyle}>
                            <img
                              crossOrigin="anonymous"
                              src={`${BASE_URL}${data.attachmentName}`}
                              alt="Uploaded Preview"
                              style={{ maxWidth: "100%", maxHeight: "100px" }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Typography>
                  <div className="col-lg-12 mt-3">
                    <button className="btn btn-submit me-2" type="submit" disabled={isSubmitting || loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Merchant" : "Add Merchant"}
                    </button>
                    <Link to={"/admin/merchants"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MerchantForm;
