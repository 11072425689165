
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon, ShoppingBagIcon } from "@heroicons/react/20/solid";

const ProductSizeFilter = ({ product, onAddToCart, loadings }) => {
    const [selectedSize, setSelectedSize] = useState(product.quantity_sizes[0]);
    const [isOpen, setIsOpen] = useState(false); // State for toggling the dropdown
    // eslint-disable-next-line no-unused-vars
    const [sizes, setSizes] = useState(product.quantity_sizes.map((size) => ({
        value: size.size,
        label: `${size.size} ${size.unit_master.unitType} - ₹${size.price.toFixed()}`,
        price: size.price,
        discount: `${Math.round(size.discount_master.percentage)}% off`,
        originalPrice: size.price.toFixed(),
    })));

    const handleSelectChange = (selectedSize) => {
        const packageData = product.quantity_sizes.find(
            (size) => size.size === selectedSize.value
        );
        setSelectedSize(packageData);
        setIsOpen(false); // Close the dropdown after selecting
    };

    const discountedPrice = selectedSize.price - (selectedSize.price * (selectedSize.discount_master.percentage / 100));

    return (
        <main className="lg:w-1/2 lg:pl-8 w-full mt-2">
            <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
                <article>
                    <h4 className="text-xl font-bold text-gray-900 leading-tight mb-3">
                        {product.productName}
                    </h4>

                    {/* Rating Section */}
                    <div className="rating-wrap my-2 flex items-center space-x-2">
                        <ul className="rating-stars flex space-x-1">
                            {[...Array(4)].map((_, index) => (
                                <li key={index} className="text-yellow-400">★</li>
                            ))}
                            <li className="text-gray-300">★</li>
                        </ul>
                        <b className="text-gray-900">4.5</b>
                        <span className="text-gray-500">154 orders</span>
                    </div>

                    {/* Price Section */}
                    <div className="mt-2 flex items-center justify-start space-x-1 mb-3">
                        <h6 className="price text-3xl font-bold text-black mt-2 space-x-1">
                            <var className="text-3xl font-bold">₹{discountedPrice.toFixed()}</var>
                            <span className="line-through font-sans text-sm text-gray-400">
                                ₹{selectedSize.price.toFixed()}
                            </span>
                            <span className="discount font-semibold text-sm text-green-500">
                                {Math.round(selectedSize.discount_master.percentage)}% off
                            </span>
                        </h6>
                    </div>

                    {/* Product Details Section */}
                    <dl className="grid grid-cols-2 gap-x-4 text-gray-600">
                        <dt className="font-bold">Type:</dt>
                        <dd>{product.organicType}</dd>
                        <dt className="font-bold">Packet Size:</dt>
                        <dd>{selectedSize.size}{selectedSize.unit_master.unitType}</dd>
                        <dt className="font-bold">Categories:</dt>
                        <dd>{product.categories_master.categoriesName}</dd>
                        <dt className="font-bold">Material:</dt>
                        <dd>{product.vegetarianType}</dd>
                        <dt className="font-bold">Brand:</dt>
                        <dd>{product.brand_master.brandName}</dd>
                        <dt className="font-bold">Seller:</dt>
                        <dd>{product.merchant.shopName} ({product.merchant.branchCode})</dd>
                        <dt className="font-bold">Description:</dt>
                    </dl>
                    <p className="text-gray-600 mb-4">{product.description}</p>

                    <hr className="my-4" />
                    <div className="flex flex-wrap mb-4 gap-2">
                        <div className="w-full md:w-1/2 mb-2">
                            <label className="form-label font-bold">Packet Size:</label>
                            <div className="relative">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="p-2 border border-solid border-silverSurfer-200 shadow-5 rounded-2xs focus:outline-none w-80 bg-white"
                                >
                                    {sizes.find(size => size.value === selectedSize.size)?.label}
                                </button>

                                {isOpen && (
                                    <ul className="absolute mt-1 w-full bg-white border border-solid border-silverSurfer-200 shadow-5 rounded-2xs focus:outline-none max-h-80 overflow-auto z-10">
                                        {sizes.map((size) => (
                                            <li
                                                key={size.value}
                                                className="p-2 cursor-pointer hover:bg-gray-200"
                                                onClick={() => handleSelectChange(size)}
                                            >
                                                {/* Single-line Card Style */}
                                                <div className="border border-gray-300 rounded p-3 flex justify-between items-center shadow-sm hover:shadow-md hover:bg-gray-100">
                                                    <div className="text-sm font-semibold text-darkOnyx-800 truncate">
                                                        {size.label}
                                                    </div>
                                                    <div className="text-gray-600 font-bold">
                                                        ₹{size.price}
                                                    </div>
                                                    {size.discount && (
                                                        <div className="text-green-500 text-sm">{size.discount}</div>
                                                    )}

                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-center items-center mt-2">
                        <div className="flex justify-between gap-4 flex-wrap w-full mt-4 lg:mt-6">
                            {/* Add to Cart Button */}
                            <Link
                                onClick={() => onAddToCart({ product, quantitySizeId: selectedSize.id })}
                                className="btn justify-center shadow-sm text-sm py-3 px-4 bg-sky-400 text-white rounded-lg hover:bg-sky-600 transition duration-300 flex items-center font-bold w-full sm:w-auto"
                            >
                                {loadings ? (
                                    <div className="border-t-4 text-white border-solid w-4 h-4 rounded-full animate-spin"></div>
                                ) : (
                                    <ShoppingBagIcon className="h-6 w-6 text-white" />
                                )}
                                <span className="ml-2">{loadings ? "Loading..." : "Add to Cart"}</span>
                            </Link>

                            {/* Back to Shop Button */}
                            <Link
                                to="/"
                                className="btn shadow-sm justify-center text-sm py-3 px-4 border border-gray-300 text-orange-500 rounded-md hover:bg-orange-100 transition duration-300 flex items-center font-bold w-full sm:w-auto"
                            >
                                <ArrowLeftIcon className="h-6 w-6 text-red-500" />
                                <span className="ml-2">Back to Shop</span>
                            </Link>
                        </div>
                    </div>
                </article>
            </div>
        </main>
    );
};

export default ProductSizeFilter;
