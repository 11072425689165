import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`/admin/users?page=${page}&size=${size}&search=${search}`);
};


const UserService = {
  findAll,
};

export default UserService;
