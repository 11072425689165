
import { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts } from "../../../features/product.slice";
// import { addToCartAPI } from "../../../features/cart.slice";
// import { selectCurrentUser } from "../../../features/auth.slice";
// import { useLocation, useNavigate } from "react-router-dom";
// import Snackbar from "../../../components/Snackbar/Snackbar";

const Products = () => {
    const [sortBy, setSortBy] = useState("name");
    const { items, status, error } = useSelector((state) => state.products);
    // const currentUser = useSelector(selectCurrentUser);
    // const navigate = useNavigate();
    // const location = useLocation();
    const dispatch = useDispatch();
    // const [snackbarMessage, setSnackbarMessage] = useState("");
    // const [snackbarVisible, setSnackbarVisible] = useState(false);
    // const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        if (status === 'idle') {        
            dispatch(fetchAllProducts());
        }
    }, [status, dispatch]);

    // const handleAddToCart = (product) => {
    //     if (!currentUser) {
    //         const redirectPath = location.state?.from || '/login';
    //         navigate(redirectPath);
    //     } else {
    //         dispatch(addToCartAPI({ productId: product.id, quantity }))
    //             .then(() => {
    //                 setSnackbarMessage(`${product.productName} added to cart!`);
    //                 setSnackbarVisible(true);
    //                 setTimeout(() => setSnackbarVisible(false), 3000);
    //             })
    //             .catch(() => {
    //                 setSnackbarMessage('Failed to add item to cart.');
    //                 setSnackbarVisible(true);
    //                 setTimeout(() => setSnackbarVisible(false), 3000);
    //             });
    //     }
    // };

    const sortedProducts = [...items].sort((a, b) => {
        if (sortBy === "price") {
            return a.price - b.price;
        }
        return a.productName?.localeCompare(b.productName);
    });

    return (
        <>
            <div className="row">
                <div className="col-md-12 col-sm-12 tabs_wrapper">
                    <div className="page-header">
                        <div className="page-title uppercase">
                            <h5><b>Products</b></h5>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4 filter-sort mb-2">
                        <div className="filter-sort-select justify-center">
                            <label className="mr-2">Sort by: </label>
                            <select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                                aria-label="Sort products by"
                                className="rounded-full gap-2 px-4 py-1.5 text-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="name">Name</option>
                                <option value="price">Price</option>
                            </select>
                        </div>
                    </div>

                    <div className="tabs_container">
                        <div className="tab_content active" data-tab="fruits">
                            <div className="row">
                                {status === 'loading' && (
                                    Array.from({ length: 8 }).map((_, index) => (
                                        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
                                            <div className="bg-gray-200 animate-pulse p-4 rounded-lg shadow-lg">
                                                <div className="h-40 bg-gray-300 rounded-md"></div>
                                                <div className="mt-4 h-6 bg-gray-300 rounded-md"></div>
                                                <div className="mt-2 h-4 bg-gray-300 rounded-md w-1/2"></div>
                                                <div className="mt-2 h-4 bg-gray-300 rounded-md w-1/2"></div>
                                                <div className="mt-2 h-4 bg-gray-300 rounded-md w-1/2"></div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {status === 'failed' && (
                                    <div>
                                        <p>Error: {error}</p>
                                        {error.includes('404') && <p>The products you are looking for are not available.</p>}
                                    </div>
                                )}
                                {status === 'succeeded' && (
                                    <>
                                        {items.length > 0 ? (
                                            sortedProducts.map((product, index) => (
                                                <ProductCard
                                                    key={index}
                                                    data={product}
                                                    //onAddToCart={handleAddToCart}
                                                />
                                            ))
                                        ) : (
                                            <p>No products available!</p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {snackbarVisible && (
                <Snackbar
                    message={snackbarMessage}
                    visible={snackbarVisible}
                    onClose={() => setSnackbarVisible(false)}
                />
            )} */}
        </>
    );
};

export default Products;
